/**
 * @module EditPaymentMethodScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { PaymentIcon } from '../../../../PaymentIcon';
import { ANALYTICS, STRINGS } from '../../../../../utils';
import lockIcon from '../../../../../assets/svg/icon-lock.svg';
import '../../../Modal.scss';
import '../../ManagePaymentMethod.scss';

const { edit: editStrings } = STRINGS.modals.managePaymentMethod;
const STYLES = {
  accountCardNumber: {
    backgroundImage: `url(${lockIcon})`,
    backgroundPosition: 'right 16px center',
    backgroundRepeat: 'no-repeat',
    paddingRight: '34px', // Right padding + 8px gap + width of icon.
  },
};
const FORM_FIELD_IDS = {
  accountCardNumber: 'edit-payment-method-form-account-card-number',
  expirationDate: 'edit-payment-method-form-expiration-date',
  name: 'edit-payment-method-form-name',
};

/**
 * Represents the HTML markup and contents for the manage payment method modal's edit payment method.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {object} [props.formData] - Data object of form data values. Attributes align with form input field names.
 * @param {Function} [props.onFormChange] - Handler function for form change event trigger. The form data object { expirationDate, name } is sent as function argument.
 * @param {PaymentMethod} props.paymentMethodData - The payment method data object.
 *
 * @returns {React.ReactElement} The EditPaymentMethodScreen component.
 */
export function EditPaymentMethodScreen({
  className,
  formData,
  onFormChange,
  paymentMethodData,
  ...passThroughProps
}) {
  const isCreditCard =
    paymentMethodData?.attributes?.payment_method_type === 'Credit Card';
  const expirationDateRegExp = /^((0[1-9]|1[0-2])|([1-9]))\/?(\d{4}|\d{2})$/;

  /**
   * Note: The ignore directives are added because the events will *always*
   * have an Event object associated with it. The optional chaining used is
   * simply an absolute failsafe to guard against null values. Additionally,
   * there is test coverage for triggering these events with no Event object
   * passed along with it, which *should* cover these edge cases but still
   * shows them as uncovered.
   */

  /**
   * Handler function for payment method expiration date input field change.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleExpirationDateChange(event) {
    onFormChange({
      attributes: {
        ...formData?.attributes,
        expiration_label: /* istanbul ignore next */ event?.target?.value,
      },
      errors: {
        ...formData?.errors,
        expirationDate: !expirationDateRegExp.test(
          /* istanbul ignore next */ event?.target?.value,
        ),
      },
    });
  }

  /**
   * Handler function for payment method expiration date input field blur.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleExpirationDateBlur(event) {
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.editPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.expirationDate,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.editPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  /**
   * Handler function for payment method expiration date input field focus.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleExpirationDateFocus(event) {
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.editPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.expirationDate,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.editPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  /**
   * Handler function for payment method nickname input field change.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleNameChange(event) {
    onFormChange({
      attributes: {
        ...formData?.attributes,
        name: /* istanbul ignore next */ event?.target?.value,
      },
      errors: {
        ...formData?.errors,
        name: /* istanbul ignore next */ event?.target?.value?.length > 32,
      },
    });
  }

  /**
   * Handler function for payment method nickname input field blur.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleNameBlur(event) {
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.editPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.nickname,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.editPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  /**
   * Handler function for payment method nickname input field focus.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleNameFocus(event) {
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.editPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.nickname,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.editPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  return (
    <div
      className={`${className || ''}`}
      data-testid="edit-payment-method-screen"
    >
      <div className="screen-content">
        <div className="list">
          <div className="content-block form-container vertical">
            <PaymentIcon
              className="ml-auto mr-auto"
              height={64}
              paymentMethodData={paymentMethodData}
            />
            <div className="form-field-control">
              <label htmlFor="accountCardNumber">
                {
                  editStrings.labels[
                    isCreditCard ? 'cardNumber' : 'accountNumber'
                  ]
                }
              </label>
              <input
                data-testid={FORM_FIELD_IDS.accountCardNumber}
                disabled={true}
                id={FORM_FIELD_IDS.accountCardNumber}
                name="accountCardNumber"
                style={STYLES.accountCardNumber}
                type="text"
                value={`****${paymentMethodData?.attributes?.last_4}`}
              />
            </div>
            {isCreditCard ? (
              <div className="form-field-control">
                <label htmlFor="expirationDate">
                  {editStrings.labels.expirationDate}
                </label>
                <input
                  className={formData?.errors?.expirationDate ? 'error' : ''}
                  data-testid={FORM_FIELD_IDS.expirationDate}
                  id={FORM_FIELD_IDS.expirationDate}
                  name="expirationDate"
                  onBlur={handleExpirationDateBlur}
                  onChange={handleExpirationDateChange}
                  onFocus={handleExpirationDateFocus}
                  placeholder={editStrings.placeholders.expirationDate}
                  type="text"
                  value={formData?.attributes?.expiration_label || ''}
                />
              </div>
            ) : null}
            <div className="form-field-control">
              <label htmlFor="name">
                {`${editStrings.labels.nickname}`}{' '}
                <span className="note">{editStrings.labels.optional}</span>
              </label>
              <input
                className={formData?.errors?.name ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.name}
                id={FORM_FIELD_IDS.name}
                name="name"
                onBlur={handleNameBlur}
                onChange={handleNameChange}
                onFocus={handleNameFocus}
                placeholder={editStrings.placeholders.name}
                type="text"
                value={formData?.attributes?.name || ''}
              />
              <p className="counter text-right mb-0 mt-0">{`${
                formData?.attributes?.name ? formData.attributes.name.length : 0
              }/${editStrings.variables.nameMax}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
