/**
 * @module PaperlessPreferenceModal
 */
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { base } from '@io/web-tools-io/dist/themes';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { updateUser } from '../../../api/profile';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { EmailAddressScreen } from './screens/EmailAddress';
import { MailingAddressScreen } from './screens/MailingAddress';
import { PaperlessPreferenceScreen } from './screens/PaperlessPreference';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import {
  ANALYTICS,
  COUNTRIES,
  HTTP_METHODS,
  ICON_OVERRIDES,
  MODAL_MODES,
  STRINGS,
  logError,
} from '../../../utils';
import LeafIcon from '../../ui/LeafIcon';
import '../Modal.scss';
import './PaperlessPreference.scss';

/**
 * Convenience variables to store and track strings, years, and defaults.
 */
const paperlessPreferenceStrings = STRINGS.modals.paperlessPreference;

/**
 * Represents the modal to show user paperless preference settings.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.iconOverride] - Optional icon override to use in place of default "x" close icon.
 * @param {boolean} [props.isOpen] - Boolean flag denoting the visibility of the modal.
 * @param {'email-address'|'mailing-address'|'main'} props.mode - The mode of the modal.
 * @param {'confirm'|'update'} props.secondaryMode - The secondary mode of the modal, used to set the mode of the secondary screen(s) for Email and Mailing address.
 *
 * @returns {React.ReactElement} The PaperlessPreferenceModal component.
 */
export function PaperlessPreferenceModal({
  iconOverride,
  isOpen,
  mode,
  secondaryMode,
  ...passThroughProps
}) {
  const { fetchProfileData, getAccessToken, user, userProfileData } = useAuth();
  const { paperlessStatus, preferredCampus } = useGiving();
  const {
    handleModalClose,
    handleModalVisibility,
    modals,
    storeConfirmationDialogData,
  } = useModals();
  const [modalMode, setModalMode] = React.useState(
    [
      MODAL_MODES.emailAddress,
      MODAL_MODES.mailingAddress,
      MODAL_MODES.main,
    ].includes(mode)
      ? mode
      : MODAL_MODES.main,
  );
  const [secondaryScreenMode, setSecondaryScreenMode] = React.useState(
    [MODAL_MODES.confirm, MODAL_MODES.update].includes(secondaryMode)
      ? secondaryMode
      : MODAL_MODES.confirm,
  );
  const [emailAddressFormData, setEmailAddressFormData] = React.useState(null);
  const [mailingAddressFormData, setMailingAddressFormData] =
    React.useState(null);
  const [isEmailAddressFormUpdated, setIsEmailAddressFormUpdated] =
    React.useState(false);
  const [isMailingAddressFormUpdated, setIsMailingAddressFormUpdated] =
    React.useState(false);
  const [emailAddressFormHasErrors, setEmailAddressFormHasErrors] =
    React.useState(false);
  const [mailingAddressFormHasErrors, setMailingAddressFormHasErrors] =
    React.useState(false);
  const [networkRequestStatus, setNetworkRequestStatus] = React.useState({
    method: null,
    processing: false,
  });
  const modalTitle =
    modalMode === MODAL_MODES.main
      ? paperlessPreferenceStrings[modalMode].title
      : `${paperlessPreferenceStrings[modalMode].titlePrefixes[secondaryScreenMode]} ${paperlessPreferenceStrings[modalMode].title}`;

  /**
   * Handler function to trigger call to Profile API endpoint for updating user profile.
   *
   * Note: Ignore directive added since save (update) button trigger and functionality covered in individual modal screen test.
   *
   * @param {object} formData - Data object of form data.
   */
  /* istanbul ignore next */
  async function callApiUpdateProfile(formData) {
    setNetworkRequestStatus({
      method: HTTP_METHODS.patch,
      processing: true,
    });

    try {
      const updatedProfileResponse = await updateUser({
        accessToken: getAccessToken(),
        attributes: formData.attributes,
      });
      if (updatedProfileResponse?.errors) {
        const [responseError] = updatedProfileResponse.errors;
        logError(new Error(responseError.detail));
        setNetworkRequestStatus({
          method: null,
          processing: false,
        });

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(
          new Error(paperlessPreferenceStrings.errors.updateProfileError),
          {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          },
        );
      } else {
        callSegmentTrack({
          event: ANALYTICS.events.paymentMethodUpdated,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.screens.names.paperlessPreferenceModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.save,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });

        // Re-fetch user profile data and set back to main mode.
        fetchProfileData({
          callback: async (data) => {
            const updatedPaperlessStatus = data?.paperlessStatus;
            setEmailAddressFormData({
              attributes: {
                email: updatedProfileResponse?.email,
              },
              errors: {
                email: false,
              },
            });
            setNetworkRequestStatus({
              method: null,
              processing: false,
            });

            // Show confirmation dialog if user enrolled in Paperless.
            if (updatedPaperlessStatus) {
              storeConfirmationDialogData({
                cancelLabel:
                  paperlessPreferenceStrings.dialogs
                    .paperlessStatementConfirmation.cancelLabel,
                confirmLabel: null,
                icon: (
                  <LeafIcon
                    color={base.colors.utilityValidGreen}
                    style={{ height: '72px', width: 'auto' }}
                  />
                ),
                isOpen: true,
                message:
                  paperlessPreferenceStrings.dialogs
                    .paperlessStatementConfirmation.message,
                onCancelClick: () => {
                  storeConfirmationDialogData({ isOpen: false });
                  callSegmentTrack({
                    event: ANALYTICS.events.buttonAction,
                    properties: {
                      action: ANALYTICS.actions.clicked,
                      component:
                        ANALYTICS.screens.names.paperlessPreferenceModal,
                      component_url: null,
                      context: ANALYTICS.contexts.oneScreen,
                      label:
                        paperlessPreferenceStrings.dialogs
                          .paperlessStatementConfirmation.cancelLabel,
                      logged_in: !!user,
                      preferred_campus: preferredCampus?.attributes?.code,
                      referrer: document?.referrer,
                      screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                      title: document?.title,
                      url: window?.location?.href,
                      user_id:
                        user?.['https://www.life.church/rock_person_alias_id'],
                    },
                  });
                  // Timeout to match transition time of dialog hiding.
                  setTimeout(() => {
                    setModalMode(MODAL_MODES.main);
                  }, 300);
                },
                onClose: () => {
                  storeConfirmationDialogData({ isOpen: false });
                  callSegmentTrack({
                    event: ANALYTICS.events.buttonAction,
                    properties: {
                      action: ANALYTICS.actions.clicked,
                      component:
                        ANALYTICS.screens.names.paperlessPreferenceModal,
                      component_url: null,
                      context: ANALYTICS.contexts.oneScreen,
                      label: ANALYTICS.labels.close,
                      logged_in: !!user,
                      preferred_campus: preferredCampus?.attributes?.code,
                      referrer: document?.referrer,
                      screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                      title: document?.title,
                      url: window?.location?.href,
                      user_id:
                        user?.['https://www.life.church/rock_person_alias_id'],
                    },
                  });
                  // Timeout to match transition time of dialog hiding.
                  setTimeout(() => {
                    setModalMode(MODAL_MODES.main);
                  }, 300);
                },
                title:
                  paperlessPreferenceStrings.dialogs
                    .paperlessStatementConfirmation.title,
              });
            } else {
              setModalMode(MODAL_MODES.main);
            }
          },
          forceFetch: true,
        });
      }
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(
        new Error(paperlessPreferenceStrings.errors.updateProfileError),
        {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        },
      );

      // Re-fetch user profile data and set back to main mode.
      fetchProfileData({
        callback: () => {
          setNetworkRequestStatus({
            method: null,
            processing: false,
          });
          setModalMode(MODAL_MODES.main);
        },
        forceFetch: true,
      });
    }
  }

  /**
   * Handler function for modal close/navigate back event.
   *
   * Note: Jest test coverage intentionally ignored due this using in-component
   * state for the switch. Coverage for both cases are included in test file.
   */
  /* istanbul ignore next */
  function handleClose() {
    switch (modalMode) {
      case MODAL_MODES.emailAddress:
        if (isEmailAddressFormUpdated) {
          storeConfirmationDialogData({
            cancelLabel:
              paperlessPreferenceStrings.dialogs.emailAddressFormUnsavedChanges
                .cancelLabel,
            confirmLabel:
              paperlessPreferenceStrings.dialogs.emailAddressFormUnsavedChanges
                .confirmLabel,
            icon: null,
            isOpen: true,
            message:
              paperlessPreferenceStrings.dialogs.emailAddressFormUnsavedChanges
                .message,
            onCancelClick: () => {
              storeConfirmationDialogData({ isOpen: false });
              setIsEmailAddressFormUpdated(false);
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    paperlessPreferenceStrings.dialogs
                      .emailAddressFormUnsavedChanges.cancelLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              // Timeout to match transition time of dialog hiding.
              setTimeout(() => {
                setModalMode(MODAL_MODES.main);
              }, 300);
            },
            onClose: () => {
              storeConfirmationDialogData({ isOpen: false });
              setIsEmailAddressFormUpdated(false);
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
            },
            onConfirmClick: () => {
              storeConfirmationDialogData({ isOpen: false });
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    paperlessPreferenceStrings.dialogs
                      .emailAddressFormUnsavedChanges.confirmLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              callApiUpdateProfile({
                ...emailAddressFormData,
                attributes: {
                  ...emailAddressFormData?.attributes,
                  paperless_statements_optin: 'True',
                },
              });
            },
            title: null,
          });
        } else {
          setModalMode(MODAL_MODES.main);
        }
        break;
      case MODAL_MODES.mailingAddress:
        if (isMailingAddressFormUpdated) {
          storeConfirmationDialogData({
            cancelLabel:
              paperlessPreferenceStrings.dialogs
                .mailingAddressFormUnsavedChanges.cancelLabel,
            confirmLabel:
              paperlessPreferenceStrings.dialogs
                .mailingAddressFormUnsavedChanges.confirmLabel,
            icon: null,
            isOpen: true,
            message:
              paperlessPreferenceStrings.dialogs
                .mailingAddressFormUnsavedChanges.message,
            onCancelClick: () => {
              storeConfirmationDialogData({ isOpen: false });
              setIsMailingAddressFormUpdated(false);
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    paperlessPreferenceStrings.dialogs
                      .mailingAddressFormUnsavedChanges.cancelLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              // Timeout to match transition time of dialog hiding.
              setTimeout(() => {
                setModalMode(MODAL_MODES.main);
              }, 300);
            },
            onClose: () => {
              storeConfirmationDialogData({ isOpen: false });
              setIsMailingAddressFormUpdated(false);
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
            },
            onConfirmClick: () => {
              storeConfirmationDialogData({ isOpen: false });
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.paperlessPreferenceModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    paperlessPreferenceStrings.dialogs
                      .emailAddressFormUnsavedChanges.confirmLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.paperlessPreferenceModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              callApiUpdateProfile({
                ...mailingAddressFormData,
                attributes: {
                  ...mailingAddressFormData?.attributes,
                  paperless_statements_optin: 'False',
                },
              });
            },
            title: null,
          });
        } else {
          setModalMode(MODAL_MODES.main);
        }
        break;
      default:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paperlessPreferenceModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paperlessPreferenceModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        handleModalClose(modals.paperlessPreference.id);
        break;
    }
  }

  /**
   * Handler function for Help button click event.
   */
  function handleHelpClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.help,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paperlessPreferenceModal,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.help.id,
    });
  }

  /**
   * Handler function for modal footer save button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleModalFooterSaveButton(event) {
    event.preventDefault();
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.save,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paperlessPreferenceModal,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Allow the ability to only trigger API call when optional testOverride
     * prop is not explicitly set in tests.
     */
    /* istanbul ignore next */
    if (!passThroughProps?.testOverride) {
      let updatedData = {};
      if (modalMode === MODAL_MODES.emailAddress) {
        updatedData = {
          ...emailAddressFormData,
          attributes: {
            ...emailAddressFormData?.attributes,
            paperless_statements_optin: 'True',
          },
        };
      } else {
        updatedData = {
          ...mailingAddressFormData,
          attributes: {
            ...mailingAddressFormData?.attributes,
            paperless_statements_optin: 'False',
          },
        };
      }
      callApiUpdateProfile(updatedData);
    }
  }

  /**
   * Handler function for modal footer enroll now button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleModalFooterEnrollNowClick(event) {
    event.preventDefault();
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: STRINGS.labels.enrollNow,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paperlessPreferenceModal,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Allow the ability to only trigger API call when optional testOverride
     * prop is not explicitly set in tests.
     */
    /* istanbul ignore next */
    if (!passThroughProps?.testOverride) {
      callApiUpdateProfile({
        ...emailAddressFormData,
        attributes: {
          ...emailAddressFormData?.attributes,
          paperless_statements_optin: 'True',
        },
      });
    }
  }

  /**
   * Handler function for email/mailing address list item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handlePaperlessPreferenceAddressListItemClick(event) {
    event.preventDefault();
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label:
          ANALYTICS.labels[
            /* istanbul ignore next */ paperlessStatus
              ? 'emailAddress'
              : 'statementMailingAddress'
          ],
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paperlessPreferenceModal,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Set main modal mode to email or mailing address, and the secondary mode
     * to either update or confirm. Note that secondary is set by determining
     * the paperlessStatus. This is opposite of the secondary mode setting in
     * the handlePaperlessPreferencesToggle() handler function, as that one is
     * intended to trigger "confirm" mode for email/mailing address.
     *
     * Also allow the ability to only trigger API call when optional
     * testOverride prop is not explicitly set in tests.
     */
    /* istanbul ignore next */
    if (!passThroughProps.testOverride) {
      const newModalMode = paperlessStatus
        ? MODAL_MODES.emailAddress
        : MODAL_MODES.mailingAddress;
      setModalMode(newModalMode);
      setSecondaryScreenMode(
        (!paperlessStatus && newModalMode === MODAL_MODES.mailingAddress) ||
          (paperlessStatus && newModalMode === MODAL_MODES.emailAddress)
          ? MODAL_MODES.update
          : MODAL_MODES.confirm,
      );
    }
  }

  /**
   * Handler function for paperless preference toggle list item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handlePaperlessPreferenceToggle(event) {
    event.preventDefault();
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.goPaperless,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paperlessPreferenceModal,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Set main modal mode to email or mailing address, and the secondary mode
     * to either update or confirm. Note that secondary is set by determining
     * the paperlessStatus. This is opposite of the secondary mode setting in
     * the handlePaperlessPreferenceAddressListItemClick() handler function, as
     * that one is intended to trigger "update" mode for email/mailing address.
     *
     * Also allow the ability to only trigger API call when optional
     * testOverride prop is not explicitly set in tests.
     */
    /* istanbul ignore next */
    if (!passThroughProps?.testOverride) {
      setModalMode(
        paperlessStatus ? MODAL_MODES.mailingAddress : MODAL_MODES.emailAddress,
      );
      setSecondaryScreenMode(
        paperlessStatus ? MODAL_MODES.update : MODAL_MODES.confirm,
      );
    }
  }

  /**
   * Handler function for the onFormChange prop for email address screen,
   * triggered from within the child component.
   *
   * Note: Ignore directive added since handler prop function is covered in the
   * child component test.
   *
   * @param {object} formData - Data object of Email Address form data.
   */
  /* istanbul ignore next */
  function handleEmailAddressFormChange(formData) {
    let isUpdated = false;
    let hasErrors = false;
    if (formData) {
      Object.entries(formData?.attributes).forEach(([attribute, value]) => {
        if (value !== userProfileData?.[attribute]) {
          isUpdated = true;
        }
      });
      Object.values(formData?.errors).forEach((value) => {
        if (value) {
          hasErrors = true;
        }
      });
      setEmailAddressFormData(formData);
      setIsEmailAddressFormUpdated(isUpdated);
      setEmailAddressFormHasErrors(hasErrors);
    }
  }

  /**
   * Handler function for the onFormChange prop for mailing address screen,
   * triggered from within the child component.
   *
   * Note: Ignore directive added since handler prop function is covered in the
   * child component test.
   *
   * @param {object} formData - Data object of Mailing Address form data.
   */
  /* istanbul ignore next */
  function handleMailingAddressFormChange(formData) {
    let isUpdated = false;
    let hasErrors = false;
    if (formData) {
      Object.entries(formData?.attributes).forEach(([attribute, value]) => {
        if (value !== userProfileData?.family?.address?.[attribute]) {
          isUpdated = true;
        }
      });
      Object.values(formData?.errors).forEach((value) => {
        if (value) {
          hasErrors = true;
        }
      });
      setMailingAddressFormData(formData);
      setIsMailingAddressFormUpdated(isUpdated);
      setMailingAddressFormHasErrors(hasErrors);
    }
  }

  /**
   * Represents the HTML markup for the modal Footer element.
   *
   * @returns {HTMLElement} The Footer content HTML markup.
   */
  const FooterContent = () => {
    if (
      modalMode === MODAL_MODES.emailAddress &&
      secondaryScreenMode === MODAL_MODES.confirm
    ) {
      return (
        <>
          <StyledButton
            className="full-width ml-0 mr-0"
            disabled={
              /* istanbul ignore next */ networkRequestStatus.processing
            }
            onClick={handleModalFooterEnrollNowClick}
            variant={ButtonVariants.primary}
          >
            {
              /* istanbul ignore next */ networkRequestStatus.processing ? (
                <div className="circular loader"></div>
              ) : (
                STRINGS.labels.enrollNow
              )
            }
          </StyledButton>
          <p className="disclaimer mb-0 mt-4 text-center">
            {
              paperlessPreferenceStrings['email-address'].disclaimers
                .emailEnrollNow
            }
          </p>
        </>
      );
    }
    return (
      <StyledButton
        className="full-width ml-0 mr-0"
        disabled={
          /* istanbul ignore next */ networkRequestStatus.processing ||
          emailAddressFormHasErrors ||
          mailingAddressFormHasErrors
        }
        onClick={handleModalFooterSaveButton}
        variant={ButtonVariants.primary}
      >
        {
          /* istanbul ignore next */ networkRequestStatus.processing ? (
            <div className="circular loader"></div>
          ) : (
            STRINGS.labels.save
          )
        }
      </StyledButton>
    );
  }; // NOSONAR

  /**
   * Convenience effect to set form data when modalMode changes.
   *
   * Note: Ignore directive added since form data functionality is covered in
   * the child component test.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (modalMode === MODAL_MODES.emailAddress) {
      setEmailAddressFormData({
        attributes: {
          email: userProfileData?.email,
        },
        errors: {
          email: false,
        },
      });
    } else if (modalMode === MODAL_MODES.mailingAddress) {
      const userAddress = userProfileData?.family?.address;
      setMailingAddressFormData({
        attributes: {
          city: userAddress?.city,
          country: COUNTRIES.find((country) => {
            return country.code.toUpperCase() === 'US';
          }),
          postal_code: userAddress?.postal_code,
          state: userAddress?.state,
          street_1: userAddress?.street_1,
          street_2: userAddress?.street_2,
        },
        errors: {
          city: false,
          country: false,
          postal_code: false,
          state: false,
          street_1: false,
          street_2: false,
        },
      });
    }
  }, [modalMode, userProfileData]);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.paperlessPreferenceModal,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paperlessPreference,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: paperlessStatus,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content paperless-preference';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName =
    iconOverride && iconOverride === ICON_OVERRIDES.back
      ? 'modal-header-mode-back'
      : '';

  if (
    [MODAL_MODES.emailAddress, MODAL_MODES.mailingAddress].includes(modalMode)
  ) {
    contentPlacementClass = 'active-2';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      className={iconOverride ? 'animate-rtl' : ''}
      content={
        <div
          className={[contentClassName, contentPlacementClass].join(' ')}
          data-testid="paperless-preference-modal"
        >
          <div className="screen-1">
            <PaperlessPreferenceScreen
              onAddressListItemClick={
                handlePaperlessPreferenceAddressListItemClick
              }
              onPaperlessPreferenceToggle={handlePaperlessPreferenceToggle}
              paperlessStatus={paperlessStatus}
              userProfileData={userProfileData}
            />
          </div>
          <div className="screen-2">
            <div className="screen-content">
              {modalMode === MODAL_MODES.emailAddress ? (
                <EmailAddressScreen
                  formData={emailAddressFormData}
                  onFormChange={handleEmailAddressFormChange}
                  preferredCampus={preferredCampus}
                  user={user}
                />
              ) : (
                <MailingAddressScreen
                  formData={mailingAddressFormData}
                  onFormChange={handleMailingAddressFormChange}
                  preferredCampus={preferredCampus}
                  user={user}
                />
              )}
            </div>
          </div>
        </div>
      }
      contentClassName={['pt-none', 'animatable', 'multi-grid'].join(' ')}
      footer={modalMode !== MODAL_MODES.main ? <FooterContent /> : null}
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            modalMode === MODAL_MODES.main ? (
              <button
                data-testid="paperless-preference-help-button"
                onClick={handleHelpClick}
              >
                {paperlessPreferenceStrings.main.labels.help}
              </button>
            ) : null
          }
          onCloseClick={handleClose}
          title={modalTitle}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
