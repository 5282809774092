/**
 * @module PaymentMethodListScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../../../../../hooks/useAuth';
import useGiving from '../../../../../hooks/useGiving';
import { ListItem } from '../../../../ListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import WarningIcon from '../../../../ui/WarningIcon';
import {
  ANALYTICS,
  BADGES,
  LAYOUT_MODES,
  SMART_PAY_PROVIDERS,
  STRINGS,
  calculateMonthOffset,
} from '../../../../../utils';
import '../../../Modal.scss';
import '../../ManagePaymentMethod.scss';

const paymentMethodStrings = STRINGS.modals.managePaymentMethod;

/**
 * Represents the view used when payment method list is empty.
 *
 * @param {object} props - The component props object.
 * @param {Function} [props.onCtaClick] - Handler function for CTA button click event.
 * @param {Campus} [props.preferredCampus] - Preferred campus data object.
 * @param {User} [props.user] - User data object.
 *
 * @returns {React.ReactElement} The PaymentMethodListEmpty component.
 */
function PaymentMethodListEmpty({ onCtaClick, preferredCampus, user }) {
  /**
   * Handler function for the CTA click event.
   */
  function handleCtaClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.managePaymentMethodList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.addNewPaymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.managePaymentMethodList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    onCtaClick();
  }

  return (
    <div
      className="no-manage-payment-method"
      data-testid="manage-payment-method-list-empty"
    >
      <div>
        <h2>{paymentMethodStrings.select.empty.title}</h2>
        <p>{paymentMethodStrings.select.empty.body}</p>
        <StyledButton
          className="black full-width ml-0 mr-0"
          onClick={handleCtaClick}
          variant={ButtonVariants.primary}
        >
          {paymentMethodStrings.select.empty.cta.label}
        </StyledButton>
      </div>
    </div>
  );
}

/**
 * Represents the HTML markup and contents for the manage payment method modal's payment method list.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {React.MutableRefObject} props.contentRef - The mutable content Ref for the list screen content wrapper.
 * @param {Function} [props.onAddPaymentMethodClick] - Handler function for the add payment method button click event.
 * @param {Function} [props.onEntryListItemClick] - Handler function for the payment method list item click event.
 *
 * @returns {React.ReactElement} The PaymentMethodListScreen component.
 */
export function PaymentMethodListScreen({
  className,
  contentRef,
  onAddPaymentMethodClick,
  onEntryListItemClick,
}) {
  const { user } = useAuth();
  const { paymentMethods, preferredCampus, today } = useGiving();

  /**
   * Handler function for list item select of payment method.
   *
   * Note: Smart pay providers not tested/mocked, thus the ignore directive.
   *
   * @param {PaymentMethod} paymentMethod - The PaymentMethod data object.
   */
  function handleExistingPaymentMethodSelect(paymentMethod) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.managePaymentMethodList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paymentMethodDetail,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.managePaymentMethodList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],

        value: /* istanbul ignore next */ Object.keys(
          SMART_PAY_PROVIDERS,
        ).includes(paymentMethod?.attributes?.payment_method_type)
          ? SMART_PAY_PROVIDERS[paymentMethod?.attributes?.payment_method_type]
              .attributes?.display_label
          : paymentMethod?.attributes?.payment_type,
      },
    });

    // Trigger handler function so parent component can transition modal state.
    onEntryListItemClick({ paymentMethod });
  }

  return (
    <div
      className={`${className || ''}`}
      data-testid="manage-payment-method-list"
    >
      <div className="screen-content" ref={contentRef}>
        {paymentMethods?.length ? (
          <div className="list mb-0">
            {paymentMethods.map((paymentMethod) => {
              const {
                display_label: displayLabel,
                expiration_label: expirationLabel,
                name,
              } = paymentMethod.attributes;
              const {
                attributes: paymentMethodAttributes,
                id: paymentMethodId,
              } = paymentMethod;
              const { exp_month: expMonth, exp_year: expYear } =
                paymentMethodAttributes;
              const monthOffset =
                expMonth !== null && expYear !== null
                  ? calculateMonthOffset({
                      endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
                      startDate: today,
                    })
                  : null;
              const expirePrefix =
                paymentMethodStrings.select[
                  `${
                    monthOffset !== null && monthOffset < 0
                      ? 'expired'
                      : 'expires'
                  }`
                ];
              let paymentMethodName = name;
              if (!paymentMethodName || paymentMethodName === '') {
                paymentMethodName = displayLabel;
              }

              let expireType = '';
              if (monthOffset < 0) {
                expireType = 'error';
              } else if (monthOffset < 2) {
                expireType = 'warning';
              }

              return (
                <ListItem
                  className="mb-16"
                  description={
                    expirationLabel ? (
                      <>
                        {expirePrefix} {expirationLabel}
                        {Object.keys(BADGES).includes(expireType) ? (
                          <div className={`badge ${expireType}`}>
                            <WarningIcon color={BADGES[expireType].color} />
                            {
                              STRINGS.labels[
                                expireType === 'error'
                                  ? 'paymentMethodExpired'
                                  : 'paymentMethodExpiring'
                              ]
                            }
                          </div>
                        ) : null}
                      </>
                    ) : null
                  }
                  inputName={paymentMethodId}
                  key={paymentMethodId}
                  layout={LAYOUT_MODES.vertical}
                  onClick={() => {
                    handleExistingPaymentMethodSelect(paymentMethod);
                  }}
                  startIcon={
                    <PaymentIcon
                      height={42}
                      paymentMethodData={paymentMethod}
                    />
                  }
                  title={paymentMethodName}
                  titleProps={{ primary: true }}
                />
              );
            })}
          </div>
        ) : (
          <PaymentMethodListEmpty
            onCtaClick={onAddPaymentMethodClick}
            preferredCampus={preferredCampus}
            user={user}
          />
        )}
      </div>
    </div>
  );
}
