/**
 * @module HelpModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { ANALYTICS, STRINGS } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { ListItem } from '../../ListItem';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import EmailIcon from '../../ui/EmailIcon';
import HelpOutlineIcon from '../../ui/HelpOutlineIcon';
import '../Modal.scss';

/**
 * Represents an icon for the sections of list items.
 *
 * @param {object} props - The component props object.
 * @param {object} props.listItem - Data object of list item.
 *
 * @returns {React.ReactElement} The ListItemStartIcon component with icon for specified list item.
 */
export function ListItemStartIcon({ listItem }) {
  switch (listItem?.startIcon) {
    case 'email':
      return <EmailIcon />;
    case 'help':
      return <HelpOutlineIcon />;
    default:
      return null;
  }
}

/**
 * Represents the modal to show Help content for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 *
 * @returns {React.ReactElement} The HelpModal component.
 */
export function HelpModal({ isOpen, ...passThroughProps }) {
  const { user } = useAuth();
  const { preferredCampus } = useGiving();
  const { handleModalClose, modals } = useModals();
  const { help: helpStrings } = STRINGS.modals;

  /**
   * Handler function for the close event triggers.
   */
  function handleClose() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHelp,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.close,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHelp,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.help.id);
  }

  /**
   * Handler function for list item click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   * @param {object} listItem - The list item data object used for Segment metrics values.
   */
  function handleListItemClick(event, listItem) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHelp,
        component_url: listItem.link,
        context: ANALYTICS.contexts.oneScreen,
        label: listItem.label,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHelp,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    event.preventDefault();
    /* istanbul ignore next */
    if (!passThroughProps?.testOverride) {
      if (listItem.windowAction === 'open') {
        window.open(listItem.link, listItem.target);
      } else {
        window.location.href = listItem.link;
      }
    }
  }

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.givingHelp,
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: preferredCampus,
        referrer: document?.referrer,
        screen_class: ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingHelp,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingHelp,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      content={
        <div className="list list-container grouped" data-testid="help-modal">
          {helpStrings.listItems.map((listItem) => {
            return (
              <ListItem
                className="flat-bottom"
                data-testid={`${listItem.inputName}-list-item`}
                inputName={listItem.inputName}
                key={`${listItem.inputName}`}
                onClick={(event) => {
                  handleListItemClick(event, listItem);
                }}
                startIcon={<ListItemStartIcon listItem={listItem} />}
                title={listItem.label}
                titleProps={{ primary: true }}
              />
            );
          })}
        </div>
      }
      contentClassName="pt-none"
      header={
        <ModalHeader onCloseClick={handleClose} title={helpStrings.title} />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
