/**
 * @module AddBankAccountScreen
 */
import React from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import LightningBoltIcon from '../../../../../assets/svg/lightning-bolt.svg';
import AccountRoutingIcon from '../../../../../assets/svg/payment/routing.svg';
import RoutingNumberIcon from '../../../../../assets/svg/payment/routing-icon.svg';
import useAuth from '../../../../../hooks/useAuth';
import useGiving from '../../../../../hooks/useGiving';
import { ANALYTICS, STRINGS } from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;
const thresholds = {
  accountNumber: {
    maxLength: 17,
    minLength: 8,
  },
  routingNumber: {
    maxLength: 9,
    minLength: 9,
  },
};

/* istanbul ignore next */
/**
 * Represents the Stripe section shown to the user when adding a new bank account.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactElement} props.captchaElement - Google reCAPTCHA element to render on the form.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {Function} props.onFormFieldChange - Handler function for form field change events.
 * @param {Function} props.onStripeError - Handler function for Stripe error event.
 * @param {Function} props.onStripeSuccess - Handler function for Stripe success event.
 * @param {React.Ref} props.reference - React Ref attributed to a hidden button to trigger form submit.
 *
 * @returns {React.ReactElement} The AddBankAccountScreen component.
 */
export function AddBankAccountScreen({
  captchaElement,
  className,
  onFormFieldChange,
  onStripeError,
  onStripeSuccess,
  reference,
}) {
  const { user } = useAuth();
  const { preferredCampus } = useGiving();
  const elements = useElements();
  const stripe = useStripe();

  const [accountNumber, setAccountNumber] = React.useState('');
  const [routingNumber, setRoutingNumber] = React.useState('');
  const [formErrors, setFormErrors] = React.useState({
    accountNumber: null,
    routingNumber: null,
  });
  const [formFocus, setFormFocus] = React.useState({
    accountNumber: false,
    routingNumber: false,
  });

  /**
   * Handler function for form submit.
   */
  async function handleFormSubmit() {
    const { error, token } = await stripe.createToken('bank_account', {
      account_number: accountNumber,
      country: 'US',
      currency: 'USD',
      routing_number: routingNumber,
    });

    if (error) {
      onStripeError(error);
    } else {
      onStripeSuccess(token);
    }
  }

  /**
   * Convenience effect to validate user input of form fields and set errors as
   * needed and appropriate.
   */
  React.useEffect(() => {
    const errors = {};
    const values = {
      accountNumber: {
        str: accountNumber.toString(),
      },
      routingNumber: {
        str: routingNumber.toString(),
      },
    };
    // Iterate over entries and dynamically validate value and setting errors.
    Object.entries(values).forEach(([field, data]) => {
      const { str } = data;
      const threshold = thresholds[field];
      const isValid =
        str.length >= threshold.minLength && str.length <= threshold.maxLength;
      errors[field] = !isValid
        ? paymentMethodStrings.add.errorHints[field]
        : null;
    });
    setFormErrors(errors);
    onFormFieldChange({ errors });
  }, [accountNumber, onFormFieldChange, routingNumber]);

  // Return if Stripe is unavailable.
  if (!stripe || !elements) {
    return null;
  }

  return (
    <div className={className}>
      <form>
        <div className="form-control g-12 grid-item">
          <label
            className={
              formErrors?.routingNumber &&
              routingNumber !== '' &&
              !formFocus?.routingNumber
                ? 'error'
                : ''
            }
            htmlFor="stripe_card_number"
          >
            {paymentMethodStrings.add.labels.routingNumber}{' '}
            <span className="ai-center flex float-right prompt">
              <img alt="icon" className="mr-8" src={RoutingNumberIcon} />
              {paymentMethodStrings.add.labels.routingNumberExample}
              <img alt="icon" className="ml-8" src={RoutingNumberIcon} />
            </span>
          </label>
          <input
            className={[
              'bank-routing-number',
              formErrors?.routingNumber &&
              routingNumber !== '' &&
              !formFocus?.routingNumber
                ? 'error'
                : '',
            ].join(' ')}
            id="form_stripe_bank_routing_number"
            inputMode="numeric"
            max={thresholds?.routingNumber?.maxValue}
            min={thresholds?.routingNumber?.minValue}
            name="stripe_bank_routing_number"
            onBlur={
              /* istanbul ignore next */ () => {
                setFormFocus((prevFocus) => {
                  return {
                    ...prevFocus,
                    routingNumber: false,
                  };
                });
              }
            }
            onChange={(event) => {
              setRoutingNumber(event.target.value);
            }}
            onFocus={
              /* istanbul ignore next */ () => {
                setFormFocus((prevFocus) => {
                  return {
                    ...prevFocus,
                    routingNumber: true,
                  };
                });
                callSegmentTrack({
                  event: ANALYTICS.events.buttonAction,
                  properties: {
                    action: ANALYTICS.actions.clicked,
                    component: ANALYTICS.screens.names.addBankAccount,
                    component_url: null,
                    context: ANALYTICS.contexts.oneScreen,
                    label: ANALYTICS.labels.routingNumberField,
                    logged_in: !!user,
                    preferred_campus: preferredCampus?.attributes?.code,
                    referrer: document?.referrer,
                    screen: ANALYTICS.screens.names.addBankAccount,
                    title: document?.title,
                    url: window?.location?.href,
                    user_id:
                      user?.['https://www.life.church/rock_person_alias_id'] ||
                      null,
                  },
                });
              }
            }
            placeholder={paymentMethodStrings.add.placeholders.routingNumber}
            step="1"
            type="number"
          />
        </div>
        <div className="form-control g-12 grid-item">
          <label
            className={
              formErrors?.accountNumber &&
              accountNumber !== '' &&
              !formFocus?.accountNumber
                ? 'error'
                : ''
            }
            htmlFor="stripe_bank_account_number"
          >
            {paymentMethodStrings.add.labels.accountNumber}{' '}
            <span className="ai-center flex float-right prompt">
              {paymentMethodStrings.add.labels.accountNumberExample}
              <img alt="icon" className="ml-8" src={AccountRoutingIcon} />
            </span>
          </label>
          <input
            className={[
              'bank-account-number',
              formErrors?.accountNumber &&
              accountNumber !== '' &&
              !formFocus?.accountNumber
                ? 'error'
                : '',
            ].join(' ')}
            id="form_stripe_bank_account_number"
            inputMode="numeric"
            max={thresholds?.accountNumber?.maxValue}
            min={thresholds.accountNumber?.minValue}
            name="stripe_bank_account_number"
            onBlur={
              /* istanbul ignore next */ () => {
                setFormFocus((prevFocus) => {
                  return {
                    ...prevFocus,
                    accountNumber: false,
                  };
                });
              }
            }
            onChange={(event) => {
              setAccountNumber(event.target.value);
            }}
            onFocus={
              /* istanbul ignore next */ () => {
                setFormFocus((prevFocus) => {
                  return {
                    ...prevFocus,
                    accountNumber: true,
                  };
                });
                callSegmentTrack({
                  event: ANALYTICS.events.buttonAction,
                  properties: {
                    action: ANALYTICS.actions.clicked,
                    component: ANALYTICS.screens.names.addBankAccount,
                    component_url: null,
                    context: ANALYTICS.contexts.oneScreen,
                    label: ANALYTICS.labels.accountField,
                    logged_in: !!user,
                    preferred_campus: preferredCampus?.attributes?.code,
                    referrer: document?.referrer,
                    screen: ANALYTICS.screens.names.addBankAccount,
                    title: document?.title,
                    url: window?.location?.href,
                    user_id:
                      user?.['https://www.life.church/rock_person_alias_id'] ||
                      null,
                  },
                });
              }
            }
            placeholder={paymentMethodStrings.add.placeholders.accountNumber}
            step="1"
            type="number"
          />
        </div>
        <div className="form-control g-12 grid-item">
          <p className="ai-center align-center flex mt-0">
            <img alt="icon" src={LightningBoltIcon} />
            {paymentMethodStrings.add.labels.accountRoutingDisclaimer}
          </p>
        </div>
        <div className="form-control g-12 grid-item">{captchaElement}</div>
        <div className="form-control g-12 grid-item">
          <button
            className="visually-hidden"
            onClick={handleFormSubmit}
            ref={reference}
            type="button"
          ></button>
        </div>
      </form>
    </div>
  );
}
