/**
 * @module SelectPaymentMethodScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../../../../../hooks/useAuth';
import useGiving from '../../../../../hooks/useGiving';
import { RadioListItem } from '../../../../RadioListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import WarningIcon from '../../../../ui/WarningIcon';
import {
  ANALYTICS,
  APPLE_PAY_PAYMENT_METHOD_OBJECT,
  BADGES,
  FORM_MODES,
  GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
  LAYOUT_MODES,
  PAYMENT_METHOD_TYPES,
  PAYPAL_PAYMENT_METHOD_OBJECT,
  SMART_PAY_PROVIDERS,
  STRINGS,
  calculateMonthOffset,
} from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents the HTML markup and contents for the payment method modal when set to "select" mode.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {boolean} [props.includeSmartPay] - Boolean flag denoting whether or not to include Smart Pay options and logic (Default: true, inherited from parent component).
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main', inherited from parent component).
 * @param {Array<PaymentMethod>} [props.paymentMethods] - List of PaymentMethod objects.
 *
 * @returns {React.ReactElement} The SelectPaymentMethodScreen component.
 */
export function SelectPaymentMethodScreen({
  className,
  includeSmartPay,
  mode,
  paymentMethods,
}) {
  const { user } = useAuth();
  const {
    preferredCampus,
    scheduledGiftData,
    smartPayProviderData,
    storeScheduledGiftData,
    storeUserGivingData,
    today,
    userGivingData,
  } = useGiving();

  let selectedValue;

  /* istanbul ignore next */
  if ([FORM_MODES.managePaymentMethod, FORM_MODES.manageGift].includes(mode)) {
    selectedValue = scheduledGiftData?.attributes?.payment_method
      ? scheduledGiftData?.attributes?.payment_method?.id?.toString()
      : null;

    if (!selectedValue && includeSmartPay) {
      if (
        scheduledGiftData?.attributes?.payment_method.payment_method_type ===
        APPLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        selectedValue = APPLE_PAY_PAYMENT_METHOD_OBJECT.type;
      } else if (
        scheduledGiftData?.attributes?.payment_method.payment_method_type ===
        GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        selectedValue = GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type;
      }
    }
  } else {
    selectedValue = userGivingData?.paymentMethod
      ? userGivingData.paymentMethod.id
      : null;

    if (!selectedValue && includeSmartPay) {
      if (
        userGivingData?.paymentMethod?.attributes.payment_method_type ===
        APPLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        selectedValue = APPLE_PAY_PAYMENT_METHOD_OBJECT.type;
      } else if (
        userGivingData?.paymentMethod?.attributes.payment_method_type ===
        GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        selectedValue = GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type;
      }
    }
  }

  /* istanbul ignore next */
  if (!selectedValue && paymentMethods?.length) {
    paymentMethods?.forEach((method) => {
      if (method?.attributes?.is_default) {
        selectedValue = method?.id;
      }
    });
  }

  /**
   * Handler function for list item select of payment method.
   *
   * @param {PaymentMethod} paymentMethod - The PaymentMethod data object.
   */
  /* istanbul ignore next */
  function handleExistingPaymentMethodSelect(paymentMethod) {
    callSegmentTrack({
      event: ANALYTICS.events.givingValueUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.paymentMethodList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: Object.keys(SMART_PAY_PROVIDERS).includes(
          paymentMethod?.attributes?.payment_method_type,
        )
          ? SMART_PAY_PROVIDERS[paymentMethod?.attributes?.payment_method_type]
              .attributes?.display_label
          : paymentMethod?.attributes?.payment_type,
      },
    });

    if (
      [FORM_MODES.manageGift, FORM_MODES.managePaymentMethod].includes(mode)
    ) {
      storeScheduledGiftData({
        attributes: {
          payment_method: {
            ...paymentMethod?.attributes,
            id: paymentMethod?.id,
          },
        },
        id: scheduledGiftData.id,
        type: scheduledGiftData.type,
      });
    } else {
      storeUserGivingData({ ...userGivingData, paymentMethod });
    }
  }

  return (
    <div className={className}>
      <div className="list">
        {
          /* istanbul ignore next */ smartPayProviderData &&
          includeSmartPay &&
          Object.keys(smartPayProviderData).length ? (
            <>
              {smartPayProviderData.applePay ? (
                <RadioListItem
                  className="mb-16"
                  icon={
                    <PaymentIcon
                      height={42}
                      paymentMethodType={PAYMENT_METHOD_TYPES.apple_pay}
                    />
                  }
                  inputName={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  key={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  onChange={
                    /* istanbul ignore next */ () => {
                      handleExistingPaymentMethodSelect(
                        APPLE_PAY_PAYMENT_METHOD_OBJECT,
                      );
                    }
                  }
                  selectedValue={selectedValue}
                  title={
                    APPLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label
                  }
                  titleProps={{ primary: true }}
                  type="radio"
                  value={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                />
              ) : null}
              {smartPayProviderData.googlePay ? (
                <RadioListItem
                  className="mb-16"
                  icon={
                    <PaymentIcon
                      height={42}
                      paymentMethodType={PAYMENT_METHOD_TYPES.google_pay}
                    />
                  }
                  inputName={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  key={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  onChange={
                    /* istanbul ignore next */ () => {
                      handleExistingPaymentMethodSelect(
                        GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
                      );
                    }
                  }
                  selectedValue={selectedValue}
                  title={
                    GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label
                  }
                  titleProps={{ primary: true }}
                  type="radio"
                  value={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                />
              ) : null}
            </>
          ) : null
        }
        {
          /* istanbul ignore next */ paymentMethods?.length
            ? paymentMethods.map((paymentMethod) => {
                const {
                  display_label: displayLabel,
                  expiration_label: expirationLabel,
                  name,
                } = paymentMethod.attributes;
                const {
                  attributes: paymentMethodAttributes,
                  id: paymentMethodId,
                } = paymentMethod;
                const { exp_month: expMonth, exp_year: expYear } =
                  paymentMethodAttributes;
                const monthOffset =
                  expMonth !== null && expYear !== null
                    ? calculateMonthOffset({
                        endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
                        startDate: today,
                      })
                    : null;
                const expirePrefix =
                  paymentMethodStrings.select[
                    `${
                      monthOffset !== null && monthOffset < 0
                        ? 'expired'
                        : 'expires'
                    }`
                  ];
                let paymentMethodName = name;
                if (!paymentMethodName || paymentMethodName === '') {
                  paymentMethodName = displayLabel;
                }

                let expireType = '';
                if (monthOffset < 0) {
                  expireType = 'error';
                } else if (monthOffset < 2) {
                  expireType = 'warning';
                }

                return (
                  <RadioListItem
                    className="mb-16"
                    description={
                      expirationLabel ? (
                        <>
                          {expirePrefix} {expirationLabel}
                          {Object.keys(BADGES).includes(expireType) ? (
                            <div className={`badge ${expireType}`}>
                              <WarningIcon color={BADGES[expireType].color} />
                              {
                                STRINGS.labels[
                                  expireType === 'error'
                                    ? 'paymentMethodExpired'
                                    : 'paymentMethodExpiring'
                                ]
                              }
                            </div>
                          ) : null}
                        </>
                      ) : null
                    }
                    icon={
                      <PaymentIcon
                        height={42}
                        paymentMethodData={paymentMethod}
                      />
                    }
                    inputName={paymentMethodId}
                    key={paymentMethodId}
                    layout={LAYOUT_MODES.vertical}
                    onChange={
                      /* istanbul ignore next */ () => {
                        handleExistingPaymentMethodSelect(paymentMethod);
                      }
                    }
                    selectedValue={selectedValue}
                    title={paymentMethodName}
                    titleProps={{ primary: true }}
                    type="radio"
                    value={paymentMethodId}
                  />
                );
              })
            : null
        }
        {/* If PayPal selected for new payment method, add as list item. */}
        {
          /* istanbul ignore next */ userGivingData?.paymentMethod?.attributes
            ?.display_label ===
            PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.display_label &&
          includeSmartPay ? (
            <RadioListItem
              className="mb-16"
              icon={
                <PaymentIcon
                  height={42}
                  paymentMethodType={PAYMENT_METHOD_TYPES?.PayPal}
                />
              }
              inputName={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
              key={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
              onChange={
                /* istanbul ignore next */ () => {
                  handleExistingPaymentMethodSelect(
                    PAYPAL_PAYMENT_METHOD_OBJECT,
                  );
                }
              }
              selectedValue={selectedValue}
              title={PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.display_label}
              titleProps={{ primary: true }}
              type="radio"
              value={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
            />
          ) : null
        }
      </div>
    </div>
  );
}
