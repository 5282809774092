/**
 * @module ConfirmationModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { STRINGS } from '../../../utils';
import { BaseModal } from '../BaseModal';
import '../Modal.scss';

/**
 * Represents a confirmation modal dialog view.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.cancelLabel] - The cancel button label.
 * @param {string} [props.confirmLabel] - The confirm button label.
 * @param {string} [props.icon] - Optional icon value placed above the dialog title.
 * @param {boolean} props.isOpen - Boolean flag for whether or not dialog is open.
 * @param {string} [props.message] - The dialog message.
 * @param {Function} props.onCancelClick - The function to fire on Cancel button click.
 * @param {Function} props.onClose - The function to fire on dialog close.
 * @param {Function} props.onConfirmClick - The function to fire on Confirm button click.
 * @param {string} [props.title] - The dialog title.
 *
 * @returns {React.ReactElement} The ConfirmationModal component.
 */
export function ConfirmationModal({
  cancelLabel,
  confirmLabel,
  icon,
  isOpen,
  message,
  onCancelClick,
  onClose,
  onConfirmClick,
  title,
}) {
  return (
    <BaseModal
      className="dialog"
      content={
        <div className="confirmation-modal" data-testid="confirmation-modal">
          {icon ? (
            <div className="icon-wrapper" data-testid="confirmation-modal-icon">
              {icon}
            </div>
          ) : null}
          {title ? (
            <h3 className="confirm-title text-center">{title}</h3>
          ) : null}
          {message ? (
            <p
              className="confirm-message text-center"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          ) : null}
          <div className="btn-lineup">
            {!cancelLabel && !onCancelClick ? null : (
              <StyledButton
                onClick={onCancelClick}
                variant={ButtonVariants.secondary}
              >
                {cancelLabel || STRINGS.modals.confirmation.labels.cancel}
              </StyledButton>
            )}
            {!confirmLabel && !onConfirmClick ? null : (
              <StyledButton
                onClick={onConfirmClick}
                variant={ButtonVariants.primary}
              >
                {confirmLabel || STRINGS.modals.confirmation.labels.confirm}
              </StyledButton>
            )}
          </div>
        </div>
      }
      contentClassName="pt-none"
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
