/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @module AuthMenu
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../../hooks/useAuth';
import useGiving from '../../hooks/useGiving';
import { ANALYTICS, APP_CONFIG, STRINGS } from '../../utils';
import AuthButton from '../AuthButton';
import './authMenu.scss';

/**
 * Represents a <ul> element with list items related to authentication.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onGivingMenuClick - Handler function for Giving Menu click event.
 * @param {Function} props.onHelpClick - Handler function for Help click event.
 *
 * @returns {React.ReactElement} The AuthMenu component.
 */
function AuthMenu({ onGivingMenuClick, onHelpClick }) {
  const { isAuthenticated, isLoading, logIn, user } = useAuth();
  const { preferredCampus } = useGiving();

  /* istanbul ignore next */
  function handleAuthButtonClick(event) {
    if (event) {
      event.preventDefault();
    }

    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingForm,
        component_url: /* istanbul ignore next */ isAuthenticated
          ? `${APP_CONFIG.baseUrl}/profile/giving/`
          : null,
        context: ANALYTICS.contexts.oneScreen,
        label: !isAuthenticated
          ? ANALYTICS.labels.signIn
          : ANALYTICS.labels.profile,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingForm,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (!isAuthenticated) {
      logIn();
    } else {
      // Per decision by Product Designer/Product Manager, clicking on the
      // button when authenticated will direct the user to the Profile page.
      window.location.href = `${APP_CONFIG.baseUrl}/profile/giving/`;
    }
  }

  /**
   * Handler function for Giving Menu button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleGivingMenuClick(event) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    if (onGivingMenuClick && typeof onGivingMenuClick === 'function') {
      onGivingMenuClick();
    }
  }

  /**
   * Handler function for Help button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleHelpClick(event) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    if (onHelpClick && typeof onHelpClick === 'function') {
      onHelpClick();
    }
  }

  return (
    <>
      {
        /* istanbul ignore next */ !isLoading ? (
          <div
            className={`${isAuthenticated ? 'justify flex-end' : ''}`}
            data-testid="auth-menu"
            id="auth-menu"
          >
            {!isAuthenticated ? (
              <ul className="help-nav">
                <li>
                  <a href="/" onClick={handleHelpClick}>
                    {STRINGS.modals.help.title}
                  </a>
                </li>
              </ul>
            ) : null}
            <ul className="menu-nav">
              {isAuthenticated ? (
                <li>
                  <a href="/" onClick={handleGivingMenuClick}>
                    {STRINGS.labels.givingMenu}
                  </a>
                </li>
              ) : (
                <li>
                  <AuthButton onClick={handleAuthButtonClick} />
                </li>
              )}
            </ul>
          </div>
        ) : null
      }
    </>
  );
}

export default AuthMenu;
