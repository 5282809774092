/**
 * @module LocationModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { getStateNameFromCode } from '@io/web-tools-io/dist/utils/helpers/locationsHelper';
import { CurrentLocationIcon, MyLocationIcon } from '../../LocationIcon';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import { RadioListItem } from '../../RadioListItem';
import { ANALYTICS, FORM_MODES, STRINGS, logError } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import '../Modal.scss';

/**
 * Represents the modal to show a list of Locations for Life.Church Web Giving.
 *
 * @param {object} props - The component.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main').
 *
 * @returns {React.ReactElement} The LocationModal component.
 */
export const LocationModal = ({ isOpen, mode = FORM_MODES.main }) => {
  const { user } = useAuth();
  const {
    campuses,
    calculateUserCampusProximity,
    getCampusByAttribute,
    preferredCampus,
    scheduledGiftData,
    storeScheduledGiftData,
    storeUserGivingData,
    userGivingData,
    userPosition,
  } = useGiving();
  const { handleModalClose, modals } = useModals();
  const [sortedStates, setSortedStates] = React.useState([]);
  const [restOfStates, setRestOfStates] = React.useState({});
  const [globalCampus, setGlobalCampus] = React.useState({});
  const [campusInformation, setCampusInformation] = React.useState();
  const [currentUserLocation, setCurrentUserLocation] = React.useState(null);
  const [defaultPreferredCampus, setDefaultPreferredCampus] =
    React.useState(null);
  const [defaultCampusHasChecked, setDefaultCampusHasChecked] =
    React.useState(false);
  const locationTiming = React.useMemo(() => {
    return {
      userPosition: {
        eventTracked: false,
        presented: 0,
        resolved: 0,
      },
    };
  }, []);
  const [suggestedLocations, setSuggestedLocations] = React.useState(null);
  const selectedValue =
    mode === FORM_MODES.manageGift
      ? getCampusByAttribute('code', scheduledGiftData?.attributes?.campus)?.id
      : userGivingData?.campus?.id || null;
  const hasAllData =
    campuses &&
    globalCampus &&
    restOfStates &&
    sortedStates &&
    campusInformation;

  /**
   * Represents a radio list item populated with the specified Campus data.
   *
   * @param {object} props - The component props object.
   * @param {Campus} props.campus - The Campus data object.
   * @param {boolean} props.isCurrentLocation - Boolean to denote if current location.
   * @param {boolean} props.isMyLocation - Boolean to denote if preferred location.
   *
   * @returns {React.ReactElement} The CampusListItem component.
   */
  const CampusListItem = ({ campus, isCurrentLocation, isMyLocation }) => {
    /* istanbul ignore next */
    const code = campus?.attributes?.code ?? null;
    const fallbackDescription =
      code === 'INT' ? STRINGS.modals.location.lcoDescription : null;
    return (
      <RadioListItem
        currentLocationIcon={
          /* istanbul ignore next */ isCurrentLocation ? (
            <CurrentLocationIcon />
          ) : null
        }
        description={
          campus?.attributes?.location?.street1
            ? `${campus?.attributes?.location?.street1}, ${
                campus?.attributes?.location?.city
              }, ${getStateNameFromCode(campus?.attributes?.location?.state)}`
            : fallbackDescription
        }
        inputName="campus"
        isCurrentLocation={isCurrentLocation}
        isMyLocation={isMyLocation}
        key={`campus-${campus?.id}`}
        myLocationIcon={
          /* istanbul ignore next */ isMyLocation ? <MyLocationIcon /> : null
        }
        onChange={
          /* istanbul ignore next */ (e) => {
            callSegmentTrack({
              event: ANALYTICS.events.givingValueUpdated,
              properties: {
                action: ANALYTICS.actions.updated,
                component: ANALYTICS.screens.names.givingLocation,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.location,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                title: document?.title,
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
                value: campus?.attributes.name,
              },
            });

            e.preventDefault();
            if (mode === FORM_MODES.manageGift) {
              storeScheduledGiftData({
                attributes: {
                  campus: campus?.attributes?.code,
                },
                id: scheduledGiftData.id,
                type: scheduledGiftData.type,
              });
            } else {
              storeUserGivingData({ ...userGivingData, campus });
            }
          }
        }
        selectedValue={selectedValue}
        title={campus?.attributes?.name}
        titleProps={{ primary: true }}
        type="checkbox"
        value={campus?.id?.toLowerCase()}
      />
    );
  }; // NOSONAR

  /**
   * Handler function for close event triggers.
   *
   * @param {string} label - The label to use for the Segment tracking, which is the value from which the button/reference the close event triggers.
   */
  function handleClose(label) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingLocation,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingLocation,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.location.id);
  }

  /**
   * Convenience function to calculate and set suggested locations state.
   */
  /* istanbul ignore next */
  const calculateSuggestedLocations = React.useCallback(
    ({ positionData }) => {
      // If user position is set, check for campus within range of user and
      // determine if able to set current location. Set suggested locations
      // based on position and preferred campus(es).
      if (positionData) {
        // Position provided; find within-range campus.
        const currentLocationCampus = Object.entries(
          positionData?.campusProximity,
        ).find(([, data]) => {
          return data.isWithinRange === true;
        });
        if (currentLocationCampus?.length) {
          // Current location campus found; set state and add to suggested.
          const filteredCurrentLocationCampus = campuses.find(
            (campus) => campus?.id === currentLocationCampus[0],
          );
          setCurrentUserLocation(filteredCurrentLocationCampus);
          setSuggestedLocations((prevData) => {
            return {
              ...prevData,
              current: filteredCurrentLocationCampus,
            };
          });
        } else {
          // No current location campus; no action needed.
        }
      }
    },
    [campuses],
  );

  /**
   * Convenience effect to set campus information and sort states.
   */
  React.useEffect(() => {
    if (campuses && !defaultCampusHasChecked) {
      const campusInfo = campuses.reduce((acc, curr) => {
        const state = curr?.attributes?.location?.state;
        const code = curr?.attributes?.code;

        if ((typeof state === 'undefined' || !state) && code === 'INT') {
          acc.Global = [curr];
        } else {
          const currState = acc?.[state] ?? [];
          acc[state] = [...currState, curr];
        }
        return acc;
      }, {});
      const sortedCampus = Object.keys(campusInfo)
        .sort() // NOSONAR
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = campusInfo[key];
          return obj;
        }, {});

      // Set state values.
      setCampusInformation(campusInfo);
      setSortedStates(sortedCampus);
      const filteredCampus = campuses.find(
        (campus) => campus?.attributes?.preferred === true,
      );
      setDefaultPreferredCampus(filteredCampus);

      calculateSuggestedLocations({
        filteredCampus,
        positionData: userPosition,
      });

      // Set state values for Global campus(es) and rest of states.
      const { Global, ...rest } = sortedCampus;
      /* istanbul ignore next */
      if (sortedCampus) {
        setGlobalCampus({ Global: [...Global] });
      } else {
        setGlobalCampus({ Global: [] });
      }
      setRestOfStates(rest);
      setDefaultCampusHasChecked(true);
    }
  }, [
    calculateSuggestedLocations,
    defaultCampusHasChecked,
    campuses,
    userPosition,
  ]);

  /**
   * Handler function for Geolocation Position error success.
   *
   * @param {CustomEvent} event - The CustomEvent object, with associated `detail` attribute containing a GeolocationPositionError data object.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError}.
   */
  /* istanbul ignore next */
  const handlePositionRequestError = React.useCallback(
    (event) => {
      if (event?.detail) {
        const { code } = event.detail;
        if ([1, 2].includes(code)) {
          callSegmentTrack({
            event: ANALYTICS.events.locationPermission,
            properties: {
              action: ANALYTICS.actions.error,
              component: ANALYTICS.screens.names.givingLocation,
              component_url: null,
              context: ANALYTICS.contexts.oneScreen,
              logged_in: !!user,
              preferred_campus: preferredCampus?.attributes?.code,
              referrer: document?.referrer,
              status: ANALYTICS.labels[code === 1 ? 'denied' : 'unavailable'],
              title: document?.title,
              url: window?.location?.href,
              user_id: user?.['https://www.life.church/rock_person_alias_id'],
            },
          });
          logError(event, { browserConsole: true, bugsnag: false });
        }
      }
    },
    [preferredCampus, user],
  );

  /**
   * Handler function for Geolocation Position event success.
   *
   * @param {CustomEvent} event - The CustomEvent object, with associated `detail` attribute containing a GeolocationPosition data object.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition}.
   */
  /* istanbul ignore next */
  const handlePositionRequestSuccess = React.useCallback(
    (event) => {
      if (event?.detail) {
        const position = event.detail;
        const nowTime = new Date().getTime();
        locationTiming.userPosition.resolved = nowTime;

        callSegmentTrack({
          event: ANALYTICS.events.locationPermission,
          properties: {
            action: ANALYTICS.actions.success,
            component: ANALYTICS.screens.names.givingLocation,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            status: ANALYTICS.labels.approved,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });

        if (event.detail) {
          calculateUserCampusProximity({
            callback: (positionData) => {
              calculateSuggestedLocations({
                positionData,
                preferred: defaultPreferredCampus,
              });
              // Track segment event with duration of user position retrieval.
              if (!locationTiming.userPosition.eventTracked) {
                callSegmentTrack({
                  event: ANALYTICS.events.currentLocationFound,
                  properties: {
                    action: ANALYTICS.actions.success,
                    component: ANALYTICS.screens.names.givingLocation,
                    component_url: null,
                    context: ANALYTICS.contexts.oneScreen,
                    duration: Math.abs(
                      nowTime - (locationTiming.userPosition.presented || 0),
                    ),
                    logged_in: !!user,
                    preferred_campus: preferredCampus?.attributes?.code,
                    referrer: document?.referrer,
                    screen: ANALYTICS.screens.names.givingLocation,
                    title: document?.title,
                    url: window?.location?.href,
                    user_id:
                      user?.['https://www.life.church/rock_person_alias_id'],
                  },
                });
              }
            },
            campusList: campuses,
            position: position || {},
          });
        }
      }
    },
    [
      calculateUserCampusProximity,
      calculateSuggestedLocations,
      campuses,
      defaultPreferredCampus,
      locationTiming,
      preferredCampus,
      user,
    ],
  );

  /**
   * Convenience effect to request user location, add applicable event listener,
   * based on the existence of campuses data with address data included.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (campuses?.length && campuses[0]?.attributes?.location) {
      if (window.getLocation && typeof window.getLocation === 'function') {
        // Set location timing for user position presented.
        locationTiming.userPosition.presented = new Date().getTime();

        // Trigger location retrieval from main window object.
        window.addEventListener(
          'onPositionRequestSuccess',
          handlePositionRequestSuccess,
        );
        window.addEventListener(
          'onPositionRequestError',
          handlePositionRequestError,
        );
        window.getLocation();
        return () => {
          window.removeEventListener(
            'onPositionRequestSuccess',
            handlePositionRequestSuccess,
          );
          window.removeEventListener(
            'onPositionRequestError',
            handlePositionRequestError,
          );
        };
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campuses]);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingLocation,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingLocation,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: userGivingData?.campus?.attributes?.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasAllData ? (
    <BaseModal
      content={
        <div className="mb-16" data-mode={mode} data-testid="location-modal">
          <div className="list-container">
            {/* Set the preferred Campus */}
            {
              /* istanbul ignore next */ (suggestedLocations &&
                Object.keys(suggestedLocations).length) ||
              defaultPreferredCampus ? (
                <>
                  <p className="state">{STRINGS.modals.location.suggested}</p>
                  <div className="grouped">
                    {defaultPreferredCampus ? (
                      <CampusListItem
                        campus={defaultPreferredCampus}
                        isCurrentLocation={
                          suggestedLocations?.current?.id ===
                          defaultPreferredCampus?.id
                        }
                        isMyLocation={true}
                        key={defaultPreferredCampus?.id}
                      />
                    ) : null}
                    {suggestedLocations &&
                    Object.keys(suggestedLocations).length &&
                    suggestedLocations?.current?.id !==
                      defaultPreferredCampus?.id ? (
                      <>
                        {Object.entries(suggestedLocations).map(
                          ([key, location]) => (
                            <CampusListItem
                              campus={location}
                              isCurrentLocation={
                                key === 'current' &&
                                location?.id === currentUserLocation?.id
                              }
                              isMyLocation={
                                key === 'preferred' &&
                                location?.id === defaultPreferredCampus?.id
                              }
                              key={location?.id}
                            />
                          ),
                        )}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null
            }
          </div>

          {/* Set the global Campus */}
          <div className="list-container">
            {globalCampus &&
              Object.entries(globalCampus).map(([state, value]) => (
                <div key={state}>
                  <p>
                    {
                      /* istanbul ignore next */ state === null ||
                      state === 'null'
                        ? STRINGS.modals.location.global
                        : state
                    }{' '}
                  </p>
                  <div className="grouped">
                    {value.map((campus) => (
                      <CampusListItem campus={campus} key={`${campus.id}`} />
                    ))}
                  </div>
                </div>
              ))}
          </div>

          {/* Set the rest of the Campuses */}
          <div className="list-container">
            {restOfStates &&
              Object.entries(restOfStates).map(([state, value]) => (
                <div key={state}>
                  {
                    /* istanbul ignore next */ state &&
                    state !== 'undefined' &&
                    state !== 'null' ? (
                      <div>
                        <p>{getStateNameFromCode(state)}</p>
                        <div className="grouped">
                          {value.map((campus) => (
                            <CampusListItem
                              campus={campus}
                              isCurrentLocation={
                                campus?.id === currentUserLocation?.id
                              }
                              isMyLocation={
                                campus?.id === defaultPreferredCampus?.id
                              }
                              key={`${campus.id}`}
                            />
                          ))}
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              ))}
          </div>
        </div>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={() => {
            handleClose(ANALYTICS.labels.done);
          }}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={
            /* istanbul ignore next */ () => {
              handleClose(ANALYTICS.labels.close);
            }
          }
          title={STRINGS.modals.location.title}
        />
      }
      isOpen={isOpen}
      onClose={
        /* istanbul ignore next */ () => {
          handleClose(ANALYTICS.labels.close);
        }
      }
    />
  ) : null;
}; // NOSONAR
