/**
 * @module GivingMenuList
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { ListItem } from '../../../../ListItem';
import { ANALYTICS, STRINGS } from '../../../../../utils';
import CreditCardIcon from '../../../../ui/CreditCardIcon';
import EmailIcon from '../../../../ui/EmailIcon';
import GivingHistoryIcon from '../../../../ui/GivingHistoryIcon';
import HelpOutlineIcon from '../../../../ui/HelpOutlineIcon';
import RepeatIcon from '../../../../ui/RepeatIcon';

/**
 * Represents an icon for the sections of list items.
 *
 * @param {object} props - The component props object.
 * @param {object} props.listItem - Data object of list item.
 *
 * @returns {React.ReactElement} The ListItemStartIcon component with icon for specified list item.
 */
export function ListItemStartIcon({ listItem }) {
  switch (listItem?.startIcon) {
    case 'email':
      return <EmailIcon />;
    case 'givingHistory':
      return <GivingHistoryIcon />;
    case 'help':
      return <HelpOutlineIcon />;
    case 'manageGiving':
      return <RepeatIcon />;
    case 'managePaymentMethod':
      return <CreditCardIcon />;
    default:
      return null;
  }
}

/**
 * Represents the main view for Giving Menu.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onGivingHistoryListItemClick - Handler function for Giving History list item click.
 * @param {Function} props.onManageGivingListItemClick - Handler function for Manage Giving list item click.
 * @param {Function} props.onManagePaymentMethodsListItemClick - Handler function for Manage Payment Method list item click.
 *
 * @returns {React.ReactElement} The GivingMenuList component.
 */
export function GivingMenuList({
  onGivingHistoryListItemClick,
  onManageGivingListItemClick,
  onManagePaymentMethodsListItemClick,
  ...passThroughProps
}) {
  const { givingMenu: givingMenuStrings } = STRINGS.modals;
  const { myGiving: myGivingStrings } = givingMenuStrings.main.sections;

  /**
   * Handler function for My Giving List Item click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   * @param {object} listItem - The List Item object associated with the click event.
   */
  function onMyGivingListItemClick(event, listItem) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    switch (listItem?.inputName) {
      case 'giving-history':
        if (
          onGivingHistoryListItemClick &&
          typeof onGivingHistoryListItemClick === 'function'
        ) {
          onGivingHistoryListItemClick(event);
        }
        break;
      case 'manage-giving':
        if (
          onManageGivingListItemClick &&
          typeof onManageGivingListItemClick === 'function'
        ) {
          onManageGivingListItemClick(event);
        }
        break;
      case 'manage-payment-method':
        if (
          onManagePaymentMethodsListItemClick &&
          typeof onManagePaymentMethodsListItemClick === 'function'
        ) {
          onManagePaymentMethodsListItemClick(event);
        }
        break;
      /* istanbul ignore next */
      default:
        break;
    }
  }

  /**
   * Handler function for list item click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   * @param {object} listItem - The list item data object used for Segment metrics values.
   */
  function handleListItemClick(event, listItem) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: listItem.label,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
      },
    });

    event.preventDefault();
    /* istanbul ignore next */
    if (!passThroughProps?.testOverride) {
      if (window && listItem.windowAction === 'open') {
        window.open(listItem.link, listItem.target);
      } else {
        window.location.href = listItem.link;
      }
    }
  }

  return (
    <div className="screen-content" data-testid="giving-menu-list">
      <p className="section-title mt-0">
        {givingMenuStrings.main.sections.myGiving.title}
      </p>
      <div
        className="list list-container grouped"
        data-testid="giving-menu-my-giving-list"
      >
        {myGivingStrings.listItems.map((listItem) => {
          return (
            <ListItem
              className="flat-bottom"
              data-testid={`${listItem.inputName}-list-item`}
              description={listItem.description}
              inputName={listItem.inputName}
              key={`${listItem.inputName}`}
              onClick={(event) => {
                onMyGivingListItemClick(event, listItem);
              }}
              startIcon={<ListItemStartIcon listItem={listItem} />}
              title={listItem.label}
              titleProps={{ primary: true }}
            />
          );
        })}
      </div>
      <p className="section-title">
        {givingMenuStrings.main.sections.help.title}
      </p>
      <div
        className="list list-container grouped"
        data-testid="giving-menu-help-list"
      >
        {givingMenuStrings.main.sections.help.listItems.map((listItem) => {
          return (
            <ListItem
              className="flat-bottom"
              data-testid={`${listItem.inputName}-list-item`}
              inputName={listItem.inputName}
              key={`${listItem.inputName}`}
              onClick={(event) => {
                handleListItemClick(event, listItem);
              }}
              startIcon={<ListItemStartIcon listItem={listItem} />}
              title={listItem.label}
              titleProps={{ primary: true }}
            />
          );
        })}
      </div>
    </div>
  );
}
