/**
 * @module GivingHistoryModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { GivingDetailScreen } from './Screens/GivingDetail';
import { GivingHistoryListScreen } from './Screens/GivingHistoryList';
import { GivingStatementsScreen } from './Screens/GivingStatements';
import { RadioList } from '../../RadioList';
import { Loading } from '../../../views/Loading';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import {
  ANALYTICS,
  APP_CONFIG,
  ICON_OVERRIDES,
  MODAL_MODES,
  STRINGS,
  logError,
  sortGivingHistory,
} from '../../../utils';
import '../Modal.scss';
import './GivingHistory.scss';

/**
 * Convenience variables to store and track strings, years, and defaults.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;
const { givingHistoryStartYear } = APP_CONFIG;
const currentYear = new Date().getFullYear();
const allYears = Array.from(
  new Array(currentYear - givingHistoryStartYear + 1),
  (x, i) => parseInt(i, 10) + parseInt(givingHistoryStartYear, 10),
).reverse();
const defaultHistoryState = {};
allYears.forEach((year) => {
  defaultHistoryState[year] = {
    downloadUrl: null,
    entries: null,
    isDownloadRetrieving: false,
    isRetrieved: false,
    retrievedTimestamp: 0,
    year,
  };
});

/**
 * Represents the modal to show user Giving History content for Life.Church Web Giving.
 *
 * Note: For inner-component states which are altered via callbacks and
 * handlers from component props, Jest ignores are used as their functionality
 * have been covered in tests for the modal.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.iconOverride] - Optional icon override to use in place of default "x" close icon.
 * @param {History} [props.initHistoryData] - Optional object of initial history data with `data` and `year` key/value pairs.
 * @param {HistoryDonation} [props.initHistoryDetailData] - Optional object of initial history detail data.
 * @param {boolean} [props.isOpen] - Boolean flag denoting the visibility of the modal.
 * @param {('detail'|'giving-statements'|'main'|'year-select')} [props.mode] - The mode (state) of the modal (Default: 'main').
 *
 * @returns {React.ReactElement} The GivingHistoryModal component.
 */
export function GivingHistoryModal({
  iconOverride,
  initHistoryData,
  initHistoryDetailData,
  isOpen,
  mode,
  ...passThroughProps
}) {
  /**
   * If initial history data is legitimate, set the default history state with
   * the appropriate history data for the year, prior to using this in history
   * state for initial component render. This also sets the `initYear` variable
   * that is used to initially set state for `selectedYear` to ensure parity
   * between init data and auto-year calculation in case they differ.
   *
   * Note: Jest test coverage ignored for conditional and selectedYear state
   * initial setting due to initialHistoryData always being used for tests and
   * coverage and thus never deviating from a non-truthy scenario. This is
   * intentional as it's the best way to achieve test coverage by allowing the
   * component to receive initial history data and not have to mock calls to the
   * API and return data.
   */
  let initYear;
  /* istanbul ignore next */
  if (
    initHistoryData?.attributes &&
    initHistoryData?.id &&
    initHistoryData?.relationships &&
    initHistoryData?.type &&
    allYears.includes(parseInt(initHistoryData.id, 10))
  ) {
    initYear = parseInt(initHistoryData.id, 10);
    defaultHistoryState[initYear] = {
      downloadUrl: null,
      entries: sortGivingHistory(
        initHistoryData?.relationships?.donation?.data,
      ),
      isDownloadRetrieving: false,
      isRetrieved: true,
      retrievedTimestamp: new Date().getTime(),
      year: initYear,
    };
  }

  const { user } = useAuth();
  const {
    fetchGivingHistory,
    fetchGivingHistoryDownloads,
    paperlessStatus,
    preferredCampus,
    showPaperlessPrompt,
    storeShowPaperlessPromptStatus,
  } = useGiving();
  const { handleModalClose, handleModalVisibility, modals } = useModals();
  const screen1ContentRef = React.useRef(null);
  const screen2ContentRef = React.useRef(null);
  const [historyAttributes, setHistoryAttributes] =
    React.useState(defaultHistoryState);
  const [historyDetailData, setHistoryDetailData] = React.useState(
    initHistoryDetailData,
  );
  const [isDownloadRetrieving, setIsDownloadRetrieving] = React.useState(false);
  const [modalMode, setModalMode] = React.useState(
    [
      MODAL_MODES.detail,
      MODAL_MODES.givingStatements,
      MODAL_MODES.main,
      MODAL_MODES.yearSelect,
    ].includes(mode)
      ? mode
      : MODAL_MODES.main,
  );
  const [scrollPosition, setScrollPosition] = React.useState({
    screen1: 0,
    screen2: 0,
  });
  const [selectedYear, setSelectedYear] = React.useState(
    /* istanbul ignore next */ initYear || currentYear,
  );

  /**
   * Convenience function to fetch giving history data.
   *
   * Note: Jest ignore intentional due to functions being covered in tests for
   * API and other component implementations.
   *
   * @param {number|string} year - The year of which to retrieve history.
   */
  /* istanbul ignore next */
  async function getHistoryForYear(year) {
    setHistoryAttributes((prevHistory) => {
      return {
        ...prevHistory,
        [selectedYear]: {
          ...prevHistory[selectedYear],
          isRetrieved: false,
        },
      };
    });

    try {
      const historyResult = await fetchGivingHistory({ year });
      const historyEntries = historyResult?.relationships?.donation?.data || [];
      const sortedHistory = sortGivingHistory(historyEntries);

      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [selectedYear]: {
            ...prevHistory[selectedYear],
            entries: sortedHistory || null,
            isRetrieved: true,
            retrievedTimestamp: new Date().getTime(),
            year: historyResult?.id,
          },
        };
      });
    } catch (error) {
      logError(error);
    }
  }

  /**
   * Handler function for download button click event.
   *
   * Note: Jest coverage intentionally ignored due to this being called as the
   * result of a handler, which is covered in the test main mode > handles
   * download click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {number|string} year - The year of the giving statement to download.
   */
  /* istanbul ignore next */
  const handleDownloadClick = React.useCallback(async (event, year) => {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingStatements,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: `${year} ${givingHistoryStrings['giving-statements'].labels.givingStatements}`,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingStatements,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    if (historyAttributes[year]?.downloadUrl) {
      return window.open(historyAttributes[year].downloadUrl, '_blank');
    }

    // Update history attributes state with truthy value while downloading.
    setHistoryAttributes((prevHistory) => {
      return {
        ...prevHistory,
        [year]: {
          ...prevHistory[year],
          isDownloadRetrieving: true,
        },
      };
    });

    setIsDownloadRetrieving(true);

    try {
      const historyDownloadResult = await fetchGivingHistoryDownloads({
        year,
      });
      const download_url =
        historyDownloadResult?.attributes?.download_url ?? null;

      // Update state with returned URL and falsy download retrieving status.
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [year]: {
            ...prevHistory[year],
            downloadUrl: download_url,
            isDownloadRetrieving: false,
          },
        };
      });
      setIsDownloadRetrieving(false);

      // If there's a download URL, open in a new window. Otherwise, log error!
      if (download_url) {
        return window.open(download_url, '_blank');
      }
      // No need to send user-intended error to Bugsnag.
      return logError(
        new Error(givingHistoryStrings.errors.downloadUrlMissing),
        {
          bugsnag: false,
          windowAlert: true,
        },
      );
    } catch (error) {
      // Update state with returned URL and falsy download retrieving status.
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [year]: {
            ...prevHistory[year],
            isDownloadRetrieving: false,
          },
        };
      });
      logError(error);
      setIsDownloadRetrieving(false);

      // Call separate one for showing user-facing error.
      return logError(error, {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handler function for Help button click event.
   */
  function handleHelpClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.help,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.help.id,
    });
  }

  /**
   * Handler function for Give Now button click event.
   *
   * Note: Jest coverage intentionally ignored due to this being called from a
   * no-giving-history scenario, and the handler invoking onClose, which is well
   * covered in other tests for the modal. Confident to say this works.
   */
  /* istanbul ignore next */
  function handleGiveNowClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.giveNow,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.givingHistory.id);
    handleModalClose(modals.givingMenu.id);
  }

  /**
   * Handler function for giving history list item click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for main mode > list item click,
   * and due to setting state var within it.
   *
   * @param {History} data - The selected history data object.
   */
  /* istanbul ignore next */
  function handleGivingHistoryListItemClick(data) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.giftDetail,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: data?.id,
      },
    });

    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
    setHistoryDetailData(data);
    setModalMode(MODAL_MODES.detail);
  }

  /**
   * Handler function for Giving Statements button click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for the history list screen.
   */
  /* istanbul ignore next */
  function handleGivingStatementsClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingStatements,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setModalMode(MODAL_MODES.givingStatements);
  }

  /**
   * Handler function for paperless prompt Dismiss button click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for the history list screen.
   */
  /* istanbul ignore next */
  function handleDismissPaperlessPromptClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.dismiss,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    storeShowPaperlessPromptStatus(false);
  }

  /**
   * Handler function for paperless prompt Enroll Now button click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for the history list screen.
   */
  /* istanbul ignore next */
  function handleEnrollNowPaperlessPromptClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.enrollNow,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.paperlessPreference.id,
      modalProps: {
        mode: MODAL_MODES.emailAddress,
      },
    });
  }

  /**
   * Handler function for paperless preference list item button click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for the history list screen.
   */
  /* istanbul ignore next */
  function handlePaperlessPreferenceClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingStatements,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paperlessPreference,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingStatements,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.paperlessPreference.id,
      modalProps: {
        mode: MODAL_MODES.main,
      },
    });
  }

  /**
   * Handler function for modal close/navigate back event.
   *
   * Note: Jest test coverage intentionally ignored due this using in-component
   * state for the switch. Coverage for both cases are included in test file.
   *
   * Also, to avoid nested tertiaries, intentional `let` vars are used to set
   * component and screen values for Segment tracking.
   */
  /* istanbul ignore next */
  function handleClose() {
    let component;
    let screen;
    if (modalMode === MODAL_MODES.detail) {
      component = ANALYTICS.screens.names.givingHistoryDetail;
      screen = ANALYTICS.screens.names.givingHistoryDetail;
    } else if (modalMode === MODAL_MODES.givingStatements) {
      component = ANALYTICS.screens.names.givingHistoryStatements;
      screen = ANALYTICS.screens.names.givingHistoryStatements;
    } else if (modalMode === MODAL_MODES.yearSelect) {
      component = ANALYTICS.screens.names.givingHistoryFilter;
      screen = ANALYTICS.screens.names.givingHistoryFilter;
    } else {
      component = ANALYTICS.screens.names.givingHistoryList;
      screen = ANALYTICS.screens.names.givingHistoryList;
    }

    switch (modalMode) {
      case MODAL_MODES.detail:
      case MODAL_MODES.givingStatements:
      case MODAL_MODES.yearSelect:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        setModalMode(MODAL_MODES.main);
        break;
      default:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        handleModalClose(modals.givingHistory.id);
        break;
    }
  }

  /**
   * Handler function for modal footer click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for year-select mode > button
   * clicks > done button, and due to setting state var within it.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleModalFooterButton(event) {
    event.preventDefault();
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryFilter,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.done,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryFilter,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setModalMode(MODAL_MODES.main);
  }

  /**
   * Handler function for screen 1 content scroll event, which sets the scroll
   * position for the screen to be stored for ensuring accurate placement kept.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  /* istanbul ignore next */
  function handleScreen1ContentScroll() {
    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
  }

  /**
   * Handler function for year list select change event.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   *
   * @param {number|string} selectedValue - The selected value.
   */
  /* istanbul ignore next */
  function handleYearSelectChange(selectedValue) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryFilter,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: selectedValue,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryFilter,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setSelectedYear(selectedValue);
  }

  /**
   * Handler function for year select click event.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  /* istanbul ignore next */
  function handleYearSelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.filter,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingHistoryList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setModalMode(MODAL_MODES.yearSelect);
  }

  /**
   * Represents the secondary screen to display in the modal, either the
   * GivingDetailScreen or YearSelectScreen, depending on the modal's mode.
   *
   * @returns {GivingDetailScreen|YearSelectScreen} The secondary screen component, based on the modal's mode.
   */
  const SecondaryScreen = () => {
    if ([MODAL_MODES.detail, MODAL_MODES.main].includes(modalMode)) {
      return <GivingDetailScreen data={historyDetailData} />;
    }
    if (modalMode === MODAL_MODES.givingStatements) {
      return (
        <GivingStatementsScreen
          isDownloadRetrieving={isDownloadRetrieving}
          onDismissPaperlessPromptClick={handleDismissPaperlessPromptClick}
          onDownloadStatementListItemClick={handleDownloadClick}
          onEnrollNowPaperlessPromptClick={handleEnrollNowPaperlessPromptClick}
          onPaperlessPreferenceListItemClick={handlePaperlessPreferenceClick}
          paperlessStatus={paperlessStatus}
          showPaperlessPrompt={showPaperlessPrompt}
          years={allYears}
        />
      );
    }
    return (
      <RadioList
        className="list-container grouped"
        onChange={handleYearSelectChange}
        selectedValue={selectedYear}
        values={allYears}
      />
    );
  }; // NOSONAR

  /**
   * Single-run convenience effect to set and store provided giving history.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main effect/logic exists.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      initHistoryData?.id &&
      initHistoryData?.relationships?.donation?.data &&
      allYears?.includes(parseInt(initHistoryData?.id, 10))
    ) {
      const initYearFromData = parseInt(initHistoryData.id, 10);
      const sortedHistory = sortGivingHistory(
        initHistoryData.relationships.donation.data,
      );
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [initYearFromData]: {
            ...prevHistory[initYearFromData],
            entries: sortedHistory || null,
            isRetrieved: true,
            retrievedTimestamp: new Date().getTime(),
            year: initYearFromData,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect triggered with selectedYear change to fetch history, if
   * the elapsed time since initial/last retrieval for year is over configured
   * duration value. This is a total convenience integration to ensure that if
   * the user views their history, then makes a donation, then comes back to
   * look at the history, it will have been re-fetched and updated.
   *
   * Note: Jest test coverage intentionally ignored due to this always fetching
   * with tests since UI of year change is not explicitly covered in the main
   * tests but has separate coverage for click handler functionality. As such,
   * the conditional within this useEffect() will always prove truthy and will
   * show up as uncovered code.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    const nowTime = new Date().getTime();
    const selectedYearRetrievedTime =
      historyAttributes[selectedYear]?.retrievedTimestamp;
    const timeDiff = Math.floor(
      Math.abs(nowTime - selectedYearRetrievedTime) / 1000,
    );
    if (
      !historyAttributes[selectedYear]?.isRetrieved ||
      timeDiff > APP_CONFIG.givingHistoryCacheDuration
    ) {
      getHistoryForYear(selectedYear); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  /**
   * Convenience effect to set the scroll listener for the first screen.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef.current) {
      screen1ContentRef?.current?.addEventListener(
        'scroll',
        handleScreen1ContentScroll,
      );
    }
  }, [screen1ContentRef]);

  /**
   * Convenience effect to ensure the scroll position is kept for the first
   * screen, as potential re-renders may occur with transition to screen 2.
   * While this looks nearly identical to the effect above, its dependency
   * array ensures it fires off when modal mode or scroll position changes, not
   * just when the content ref gets set (as is the case above).
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = scrollPosition?.screen1;
    }
  }, [modalMode, scrollPosition.screen1]);

  /**
   * Convenience effect to set scroll top to 0 when selected year changes.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = 0;
    }
  }, [selectedYear]);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingHistoryList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingHistory,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: selectedYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to determine if year passed with Giving Statements,
   * which comes from a deep link.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (passThroughProps?.downloadStatementYear) {
      handleDownloadClick(null, passThroughProps.downloadStatementYear);
    }
  }, [handleDownloadClick, passThroughProps?.downloadStatementYear]);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content giving-history';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName =
    iconOverride && iconOverride === ICON_OVERRIDES.back
      ? 'modal-header-mode-back'
      : '';

  if (
    [
      MODAL_MODES.detail,
      MODAL_MODES.givingStatements,
      MODAL_MODES.yearSelect,
    ].includes(modalMode)
  ) {
    contentPlacementClass = 'active-2';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      className={iconOverride ? 'animate-rtl' : ''}
      content={
        <div
          className={[contentClassName, contentPlacementClass].join(' ')}
          data-testid="giving-history-modal"
        >
          {
            /* istanbul ignore next */ historyAttributes[selectedYear]
              ?.isRetrieved ? (
              <div className="screen-1">
                <GivingHistoryListScreen
                  contentRef={screen1ContentRef}
                  history={historyAttributes}
                  isDownloadRetrieving={
                    historyAttributes[selectedYear]?.isDownloadRetrieving
                  }
                  onDismissPaperlessPromptClick={
                    handleDismissPaperlessPromptClick
                  }
                  onEnrollNowPaperlessPromptClick={
                    handleEnrollNowPaperlessPromptClick
                  }
                  onEntryListItemClick={handleGivingHistoryListItemClick}
                  onGiveNowClick={handleGiveNowClick}
                  onGivingStatementsClick={handleGivingStatementsClick}
                  onYearSelectClick={handleYearSelectClick}
                  selectedYear={selectedYear}
                  showPaperlessPrompt={showPaperlessPrompt}
                />
              </div>
            ) : (
              <Loading />
            )
          }

          <div className="screen-2">
            <div className="screen-content" ref={screen2ContentRef}>
              <SecondaryScreen />
            </div>
          </div>
        </div>
      }
      contentClassName={['pt-none', 'animatable', 'multi-grid'].join(' ')}
      footer={
        modalMode === MODAL_MODES.yearSelect ? (
          <StyledButton
            className="full-width ml-0 mr-0"
            onClick={handleModalFooterButton}
            variant={ButtonVariants.primary}
          >
            {STRINGS.labels.done}
          </StyledButton>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            <button
              data-testid="giving-history-help-button"
              onClick={handleHelpClick}
            >
              {givingHistoryStrings.detail.labels.help}
            </button>
          }
          onCloseClick={handleClose}
          title={givingHistoryStrings[modalMode].title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
