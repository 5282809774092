/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module PaperlessPreferenceScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ToggleSwitch from '@io/web-tools-io/dist/components/global/ToggleSwitch';
import { base } from '@io/web-tools-io/dist/themes';
import { ListItem } from '../../../../ListItem';
import LeafIcon from '../../../../ui/LeafIcon';
import { STRINGS, formatAddress } from '../../../../../utils';
import '../../../Modal.scss';
import '../../PaperlessPreference.scss';

/**
 * Convenience variables to store and track strings, years, and defaults.
 */
const paperlessPreferenceStrings = STRINGS.modals.paperlessPreference;

/**
 * Represents a screen for main paperless preference list item options.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onAddressListItemClick - Handler function for email/mailing address list item click.
 * @param {Function} props.onPaperlessPreferenceToggle - Handler function for paperless preference toggle event.
 * @param {boolean} props.paperlessStatus - Boolean flag denoting user paperless statement status.
 * @param {object} props.userProfileData - Object of user profile data.
 *
 * @returns {React.ReactElement} The PaperlessPreferenceScreen component.
 */
export function PaperlessPreferenceScreen({
  onAddressListItemClick,
  onPaperlessPreferenceToggle,
  paperlessStatus,
  userProfileData,
}) {
  const userAddress = userProfileData?.family?.address;

  return (
    <div
      className="paperless-preference-screen"
      data-testid="paperless-preference-screen"
    >
      <div className="list">
        <p className="section-label">
          {paperlessPreferenceStrings.main.labels.paperlessPreference}
        </p>
        <div className="list list-container grouped">
          <div
            className="paperless-preference-setting-wrapper"
            onClick={onPaperlessPreferenceToggle}
          >
            <ListItem
              actionIcon={<></>}
              className="flat-bottom"
              data-testid="paperless-preference-toggle-switch"
              name="paperless-preference-toggle-switch"
              showActionIcon={true}
              startIcon={
                <LeafIcon
                  color={
                    base.colors[
                      paperlessStatus ? 'utilityValidGreen' : 'gray50'
                    ]
                  }
                  data-testid="paperless-preference-screen-leaf-icon"
                  style={{ height: '32px', width: 'auto' }}
                />
              }
              title={paperlessPreferenceStrings.main.labels.goPaperless}
              titleProps={{ large: true, primary: true }}
            />
            <ToggleSwitch
              checked={paperlessStatus}
              className="toggle-switch"
              data-testid="paperless-preference-screen-paperless-toggle"
            />
          </div>
          <ListItem
            data-testid="paperless-preference-address-list-item"
            description={
              paperlessStatus
                ? userProfileData.email
                : formatAddress({
                    city: userAddress?.city,
                    state: userAddress?.state,
                    street1: userAddress?.street_1,
                    street2: userAddress?.street_2,
                    zipCode: userAddress?.postal_code,
                  })
            }
            onClick={onAddressListItemClick}
            showActionIcon={true}
            title={
              paperlessPreferenceStrings.main.labels[
                paperlessStatus ? 'emailAddress' : 'statementMailingAddress'
              ]
            }
            titleProps={{ primary: true }}
          />
        </div>
        {paperlessStatus ? (
          <p className="disclaimer">
            {
              paperlessPreferenceStrings['email-address'].disclaimers
                .emailPaperlessPreferenceToggle
            }
          </p>
        ) : null}
      </div>
    </div>
  );
}
