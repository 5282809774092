/**
 * @module GivingDetailScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { PaymentIcon } from '../../../../PaymentIcon';
import { MONTHS, STRINGS, formatWithCommas } from '../../../../../utils';

/**
 * Convenience variable to store strings.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;

/**
 * Represents a list item containing specific giving detail data as specified
 * in the label and value props.
 *
 * @param {object} props - The component props object.
 * @param {*} [props.label] - The component label contents.
 * @param {*} [props.value] - The component value contents.
 *
 * @returns {React.ReactElement} The GivingDetailListItem component.
 */
function GivingDetailListItem({ label, value }) {
  return (
    <div className="gift-detail-list-item">
      <div className="list-item-label">{label}</div>
      <div className="list-item-value">{value}</div>
    </div>
  );
}

/**
 * Represents a view containing the details of the selected gift.
 *
 * @param {object} props - The component props object.
 * @param {History} props.data - The donation history data object.
 *
 * @returns {React.ReactElement} The GivingDetailScreen component.
 */
export function GivingDetailScreen({ data }) {
  if (!data?.attributes) {
    return (
      <div className="no-history">
        <div>
          <h2>{givingHistoryStrings.detail.empty.title}</h2>
          <p>{givingHistoryStrings.detail.empty.body}</p>
        </div>
      </div>
    );
  }

  /**
   * Create and set up vars for detailItems object to iterate over for the
   * generation of GivingDetailListItem components.
   */
  const { campus, fund, payment_amount, payment_date, payment_method } =
    data.attributes;
  const paymentDate = new Date(payment_date * 1000);
  const detailLabels = givingHistoryStrings.detail.labels;
  const detailItems = [
    {
      label: detailLabels.amount,
      value: `$${formatWithCommas(payment_amount)}`,
    },
    {
      label: detailLabels.fund,
      value: fund,
    },
    {
      label: detailLabels.location,
      value: campus,
    },
    {
      label: detailLabels.processDate,
      value: `${
        MONTHS[paymentDate?.getMonth()]?.short
      } ${paymentDate?.getDate()}, ${paymentDate?.getFullYear()}`,
    },
    {
      label: detailLabels.paymentMethod,
      value: (
        <>
          <PaymentIcon
            height={21}
            paymentMethodType={payment_method?.provider || payment_method?.type}
          />
          <span>{payment_method?.last4}</span>
        </>
      ),
    },
  ];

  return (
    <div className="list list-container grouped">
      {detailItems.map((item) => {
        return (
          <GivingDetailListItem
            key={`${item?.label}-${payment_date}`}
            label={item?.label}
            value={item?.value}
          />
        );
      })}
    </div>
  );
}
