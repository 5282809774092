/**
 * @module LocationIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { base } from '@io/web-tools-io/dist/themes';
import HouseIcon from '../ui/HouseIcon';
import LocationIcon from '../ui/LocationIcon';
import { STRINGS } from '../../utils';
import './LocationIcon.scss';

const primaryColor = base.colors.utility.action.base;
const whiteColor = base.colors.white;

const CurrentLocationIcon = ({ color = primaryColor }) => {
  return (
    <div className="icon-chip">
      <LocationIcon fillColor="transparent" strokeColor={color} />
      <span className="icon-text">{STRINGS.labels.currentLocation}</span>
    </div>
  );
};

const MyLocationIcon = () => {
  return (
    <div className="icon-chip">
      <HouseIcon color={primaryColor} />
      <span className="icon-text">{STRINGS.labels.myLocation}</span>
    </div>
  );
};

const HeaderLocationIcon = ({ color = primaryColor }) => {
  const isPrimaryColor = color === primaryColor;
  return (
    <div className={`icon-circle ${isPrimaryColor ? 'blue' : 'yellow'}`}>
      <LocationIcon fillColor={whiteColor} strokeColor={whiteColor} />
    </div>
  );
};

export { CurrentLocationIcon, HeaderLocationIcon, MyLocationIcon };
