/**
 * @module Analytics
 */

export const ANALYTICS = {
  actions: {
    addPaymentMethod: 'Add Payment Method',
    changed: 'Changed',
    clicked: 'Clicked',
    closed: 'Closed',
    collapsed: 'Collapsed',
    created: 'Created',
    deleted: 'Deleted',
    dismissed: 'Dismissed',
    enterAmount: 'Enter Amount',
    error: 'Error',
    expanded: 'Expanded',
    formSubmit: 'Form Submit',
    give: 'Give',
    opened: 'Opened',
    paused: 'Paused',
    played: 'Played',
    presented: 'Presented',
    ready: 'Ready',
    selected: 'Selected',
    selectFrequency: 'Select Frequency',
    selectFund: 'Select Fund',
    selectLocation: 'Select Location',
    showKeyboard: 'Show Keyboard',
    signIn: 'Log In',
    stopped: 'Stopped',
    success: 'Success',
    tapped: 'Tapped',
    toggledOff: 'Toggled Off',
    toggledOn: 'Toggled On',
    updated: 'Updated',
  },
  contexts: {
    guestGiving: 'Guest Giving',
    oneScreen: 'One Screen',
    oneTime: 'One Time',
    recurring: 'Recurring',
  },
  events: {
    buttonAction: 'Button Action',
    buttonTap: 'Button Tap',
    createPaymentMethodError: 'Create Payment Method Error',
    currentLocationFound: 'Current Location Found',
    deletePaymentMethod: 'Delete Payment Method',
    deleteScheduledGift: 'Delete Scheduled Gift',
    emailAddressUpdated: 'Email Address Updated',
    givingError: 'Giving Error',
    givingGave: 'Giving Gave',
    givingReady: 'Giving Ready',
    givingScheduled: 'Giving Scheduled',
    givingScheduledUpdated: 'Giving Scheduled Updated',
    givingStarted: 'Giving Started',
    givingValueUpdated: 'Giving Value Updated',
    locationPermission: 'Location Permission',
    mailingAddressUpdated: 'Mailing Address Updated',
    paymentMethodCreated: 'Payment Method Created',
    paymentMethodError: 'Payment Method Error',
    paymentMethodUpdated: 'Payment Method Updated',
    selectorDismissed: 'Selector Dismissed',
    selectorPresented: 'Selector Presented',
    updatePaymentMethod: 'Update Payment Method',
    updateScheduledGift: 'Update Scheduled Gift',
  },
  labels: {
    accountField: 'Account Field',
    add: 'Add',
    addNewPaymentMethod: 'Add New Payment Method',
    amount: 'Amount',
    approved: 'Approved',
    back: 'Back',
    bankAccountManual: 'Manually Add Bank Account',
    bankAccountSignIn: 'Sign in to Bank Account',
    city: 'City',
    close: 'Close',
    cookiePreferences: 'Cookie Preferences',
    country: 'Country',
    creditDebitCard: 'Credit / Debit Card',
    date: 'Date',
    delete: 'Delete',
    deletePaymentMethod: 'Delete Payment Method',
    denied: 'Denied',
    dismiss: 'Dismiss',
    done: 'Done',
    download: 'Download',
    edit: 'Edit',
    editPaymentMethod: 'Edit Payment Method',
    emailAddress: 'Email Address',
    enrollNow: 'Enroll Now',
    expirationDate: 'Expiration Date',
    filter: 'Filter',
    frequency: 'Frequency',
    fund: 'Fund',
    getHelp: 'Get Help',
    giftDetail: 'Gift Detail',
    giveNow: 'Give Now',
    givingDate: 'Giving Date',
    givingDynamicButton: 'Giving Dynamic Button',
    givingFrequency: 'Giving Frequency',
    givingFund: 'Giving Fund',
    givingHelp: 'Giving Help',
    givingHistory: 'Giving History',
    givingLocation: 'Giving Location',
    givingMenu: 'Giving Menu',
    givingPaymentMethod: 'Giving Payment Method',
    givingStatements: 'Giving Statements',
    goPaperless: 'Go Paperless',
    help: 'Help',
    keepIt: 'Keep It',
    learnMore: 'Learn More',
    location: 'Location',
    manageGift: 'Manage Gift',
    manageGiving: 'Manage Giving',
    manageGivingDetail: 'Manage Giving Detail',
    managePaymentMethod: 'Manage Payment Method',
    managePaymentMethods: 'Manage Payment Methods',
    missingPaymentMethod: 'Missing Payment Method Option?',
    nickname: 'Nickname',
    paperlessPreference: 'Paperless Preference',
    paymentMethod: 'Payment Method',
    paymentMethodDetail: 'Payment Method Detail',
    paymentMethodList: 'Payment Method List',
    payPal: 'PayPal',
    postal_code: 'Zip Code',
    privacyPolicy: 'Privacy Policy',
    processDate: 'Process Date',
    profile: 'Profile',
    recurringToggle: 'Recurring Toggle',
    routingNumberField: 'Routing Number Field',
    save: 'Save',
    scheduledGiftDetail: 'Scheduled Gift Detail',
    setUpRecurringGift: 'Set Up Recurring Gift',
    signIn: 'Log In',
    state: 'State',
    statementMailingAddress: 'Statement Mailing Address',
    street_1: 'Street Address 1',
    street_2: 'Street Address 2',
    termsOfUse: 'Terms of Use',
    unavailable: 'Unavailable',
    vulnerabilityDisclosureProgram: 'Vulnerability Disclosure Program',
  },
  pages: {
    error: 'Error',
    givingError: 'Giving Error',
    givingForm: 'Giving Form',
    givingGave: 'Giving Gave',
    givingHelp: 'Giving Help',
    givingMenu: 'Giving Menu',
    givingProcessing: 'Giving Processing',
    givingResult: 'Giving Result',
    givingScheduled: 'Giving Scheduled',
    maintenance: 'Maintenance',
    manageGiving: 'Manage Giving',
    manageGivingDetail: 'Manage Giving Detail',
    manageGivingList: 'Manage Giving List',
    managePaymentMethod: 'Manage Payment Method',
    managePaymentMethods: 'Manage Payment Methods',
    outage: 'Outage',
  },
  screens: {
    classes: {
      error: 'Error',
      maintenance: 'Maintenance',
      oneScreen: 'One Screen',
      oneTime: 'One Time',
      outage: 'Outage',
      recurring: 'Recurring',
    },
    names: {
      addBankAccount: 'Add Bank Account',
      addCreditCard: 'Add Credit Card',
      addPaymentMethod: 'Add Payment Method',
      confirmationDialog: 'Confirmation Dialog',
      editPaymentMethod: 'Edit Payment Method',
      editPaymentMethodModal: 'Edit Payment Method Modal',
      emailAddress: 'Email Address',
      error: 'Error',
      givingDate: 'Giving Date',
      givingForm: 'Giving Form',
      givingFrequency: 'Giving Frequency',
      givingFund: 'Giving Fund',
      givingHeader: 'Giving Header',
      givingHelp: 'Giving Help',
      givingHistoryDetail: 'Giving History Detail',
      givingHistoryFilter: 'Giving History Filter',
      givingHistoryList: 'Giving History List',
      givingLocation: 'Giving Location',
      givingMenu: 'Giving Menu',
      givingPaymentMethod: 'Giving Payment Method',
      givingResult: 'Giving Result',
      givingStatements: 'Giving Statements',
      mailingAddress: 'Mailing Address',
      maintenance: 'Maintenance',
      manageGift: 'Manage Gift',
      manageGiving: 'Manage Giving',
      manageGivingDeleteModal: 'Manage Giving Delete Modal',
      manageGivingDetail: 'Manage Giving Detail',
      manageGivingDetailModal: 'Manage Giving Detail Modal',
      manageGivingList: 'Manage Giving List',
      managePaymentMethod: 'Manage Payment Method',
      managePaymentMethodDetail: 'Manage Payment Method Detail',
      managePaymentMethodList: 'Manage Payment Method List',
      managePaymentMethods: 'Manage Payment Methods',
      myGiving: 'My Giving',
      outage: 'Outage',
      paperlessPreference: 'Paperless Preference',
      paperlessPreferenceModal: 'Paperless Preference Modal',
      paymentMethodDeleteModal: 'Payment Method Delete Modal',
      paymentMethodDetail: 'Payment Method Detail',
      paymentMethodList: 'Payment Method List',
    },
  },
};
