/**
 * @module ManageGivingModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { deleteScheduledGift, updateScheduledGift } from '../../../api/giving';
import {
  ANALYTICS,
  HTTP_METHODS,
  ICON_OVERRIDES,
  MODAL_MODES,
  SMART_PAY_PROVIDERS,
  STRINGS,
  TYPES,
  calculateDaysOffset,
  logError,
} from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { ManageGift } from './Screens/ManageGift';
import { ManageGivingList } from './Screens/ManageGivingList';
import { Loading } from '../../../views/Loading';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import '../Modal.scss';
import './ManageGiving.scss';

/**
 * Represents a view for Manage Giving.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.frequencyLabel] - Label value for the Frequency list item.
 * @param {string} [props.iconOverride] - Optional icon override to use in place of default "x" close icon.
 * @param {Array<HistoryDonation>} [props.initGivingData] - Initial user giving array of donation data objects.
 * @param {boolean} [props.isLocationCurrentLocation] - Boolean flag denoting whether or not the current location is the selected location.
 * @param {boolean} [props.isOpen] - Boolean flag denoting the visibility of the modal.
 * @param {object} [props.passThroughProps] - Optional object of additional props.
 * @param {HistoryDonation} [props.passThroughProps.initGiftData] - Initial user giving donation data object.
 * @param {('detail'|'main')} [props.passThroughProps.mode] - The mode (state) of the modal (Default: 'main').
 *
 * @returns {React.ReactElement} The ManageGivingModal component.
 */
export function ManageGivingModal({
  frequencyLabel,
  iconOverride,
  initGivingData,
  isLocationCurrentLocation,
  isOpen,
  ...passThroughProps
}) {
  const { getAccessToken, user } = useAuth();
  const {
    fetchScheduledGifts,
    frequencies,
    paymentMethods,
    preferredCampus,
    scheduledGiftData,
    smartPayProviderData,
    storeScheduledGiftData,
    storeUserGivingData,
    today,
    userGivingData,
  } = useGiving();
  const {
    handleModalClose,
    handleModalVisibility,
    modals,
    storeConfirmationDialogData,
  } = useModals();
  const { manageGiving: manageGivingStrings } = STRINGS.modals;
  const { manageGift: manageGiftStrings } = STRINGS.modals;

  const screen1ContentRef = React.useRef(null);
  const screen2ContentRef = React.useRef(null);
  const [giftListData, setGiftListData] = React.useState(initGivingData);
  const [initialScheduledGiftData, setInitialScheduledGiftData] =
    React.useState(passThroughProps?.initGiftData);

  /**
   * Note: Ignore directive added as assertions covered for sub-screens in the
   * individual tests for those components.
   */
  const [modalMode, setModalMode] = React.useState(
    /* istanbul ignore next */ passThroughProps?.mode &&
      !passThroughProps?.testOverride &&
      passThroughProps?.initGiftData
      ? passThroughProps?.mode
      : MODAL_MODES.main,
  );
  const [scrollPosition, setScrollPosition] = React.useState({
    screen1: 0,
    screen2: 0,
  });
  const [networkRequestStatus, setNetworkRequestStatus] = React.useState({
    method: null,
    processing: false,
  });
  const [editManageGiftFormHasErrors, setEditManageGiftFormHasErrors] =
    React.useState(false);

  /**
   * Convenience function to compare form data for Manage Gift to the stored
   * scheduled gift data object, and set the state for `isFormUpdated` so
   * navigating back will know whether or not to show confirmation modal.
   *
   * Note: Ignore directive added because form change handlers handled in single
   * modal component tests.
   *
   * @returns {boolean} Boolean flag denoting whether or not the form data has been updated.
   */
  /* istanbul ignore next */
  function isFormUpdated() {
    let isUpdated = false;
    Object.entries(scheduledGiftData?.attributes).forEach(
      ([attribute, value]) => {
        if (value !== initialScheduledGiftData?.attributes?.[attribute]) {
          isUpdated = true;
        }
      },
    );
    return isUpdated;
  }

  /**
   * Handler function for Give Now button click event.
   *
   * Note: Jest coverage intentionally ignored due to this being called from a
   * no-scheduled-gifts scenario, and the handler invoking onClose, which is
   * well covered in other tests for the modal. Confident to say this works.
   */
  /* istanbul ignore next */
  function handleGiveNowClick() {
    const monthlyFrequency =
      frequencies.filter((freq) => {
        return freq.attributes.name.toLowerCase() === 'monthly';
      })[0] || userGivingData.frequency;

    storeUserGivingData({
      frequency: monthlyFrequency,
    });

    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.giveNow,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    handleModalClose(modals.manageGiving.id);
    handleModalClose(modals.givingMenu.id);
  }

  /**
   * Handler function to trigger call to Giving API endpoint for deleting scheduled gift.
   */
  /* istanbul ignore next */
  async function callApiDelete() {
    setNetworkRequestStatus({
      method: HTTP_METHODS.del,
      processing: true,
    });

    try {
      await deleteScheduledGift({
        accessToken: getAccessToken(),
        giftId: scheduledGiftData?.id,
      });

      callSegmentTrack({
        event: ANALYTICS.events.deleteScheduledGift,
        properties: {
          action: ANALYTICS.actions.deleted,
          component: ANALYTICS.screens.names.manageGivingDetail,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.delete,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      const scheduledGiftsResult = await fetchScheduledGifts();
      setGiftListData(scheduledGiftsResult);
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
      setModalMode(MODAL_MODES.main);
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(manageGiftStrings.deleteScheduledGiftError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      const scheduledGiftsResult = await fetchScheduledGifts();
      setGiftListData(scheduledGiftsResult);
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
    }
  }

  /**
   * Handler function to trigger call to Giving API endpoint for updating scheduled gift.
   */
  /* istanbul ignore next */
  async function callApiUpdate() {
    setNetworkRequestStatus({
      method: HTTP_METHODS.patch,
      processing: true,
    });

    try {
      await updateScheduledGift({
        accessToken: getAccessToken(),
        amount: scheduledGiftData?.attributes?.amount,
        campus: scheduledGiftData?.attributes?.campus,
        frequency: scheduledGiftData?.attributes?.frequency,
        fund: scheduledGiftData?.attributes?.fund,
        giftId: scheduledGiftData?.id,
        nextPaymentDate: scheduledGiftData?.attributes?.next_payment_date,
        paymentMethod: scheduledGiftData?.attributes?.payment_method?.id,
      });

      const donationDate = new Date(
        scheduledGiftData.attributes.next_payment_date * 1000,
      );
      const donationDateOffset = calculateDaysOffset({
        endDate: donationDate,
        startDate: today,
      });
      const userPaymentMethods = [
        ...new Set(
          Array.from(
            paymentMethods,
            (paymentMethod) => paymentMethod.attributes.payment_method_type,
          ),
        ),
      ];
      if (smartPayProviderData?.applePay) {
        userPaymentMethods.push(
          SMART_PAY_PROVIDERS.apple_pay.attributes.display_label,
        );
      }
      if (smartPayProviderData?.googlePay) {
        userPaymentMethods.push(
          SMART_PAY_PROVIDERS.google_pay.attributes.display_label,
        );
      }

      callSegmentTrack({
        event: ANALYTICS.events.givingScheduledUpdated,
        properties: {
          action: ANALYTICS.actions.updated,
          amount: scheduledGiftData?.attributes?.amount,
          campus: scheduledGiftData?.attributes?.campus,
          component: ANALYTICS.screens.names.manageGivingDetail,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          frequency: scheduledGiftData?.attributes?.frequency,
          fund: scheduledGiftData?.attributes?.fund,
          label: ANALYTICS.labels.save,
          logged_in: !!user,
          payment_method:
            scheduledGiftData?.attributes?.payment_method?.payment_method_type,
          payment_method_id: scheduledGiftData?.attributes?.payment_method?.id,
          payment_methods: userPaymentMethods,
          person_alias_id: user?.[TYPES.user.lcRockPersonAliasId],
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          schedule_start_date: donationDate.toISOString().split('T')[0], // Outputs to YYYY-MM-DD format.
          schedule_start_date_offset: donationDateOffset,
          scheduled_gift_id: scheduledGiftData?.id,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      const scheduledGiftsResult = await fetchScheduledGifts();
      setGiftListData(scheduledGiftsResult);
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
      setModalMode(MODAL_MODES.main);
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(manageGiftStrings.updateScheduledGiftError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      const scheduledGiftsResult = await fetchScheduledGifts();
      setGiftListData(scheduledGiftsResult);
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
    }
  }

  /**
   * Handler function for add button click event.
   *
   * Note: Set frequency to monthly and close modals to show main giving form.
   * Also, get monthly frequency value to use for label and userGivingData.
   */
  function handleAddClick() {
    /* istanbul ignore next */
    const monthlyFrequency =
      frequencies.filter((freq) => {
        return freq.attributes.name.toLowerCase() === 'monthly';
      })[0] || userGivingData.frequency;

    storeUserGivingData({
      frequency: monthlyFrequency,
    });

    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.add,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.manageGiving.id);
    handleModalClose(modals.givingMenu.id);
  }

  /**
   * Handler function for add button click event.
   */
  function handleHelpClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.help,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.help.id,
    });
  }

  /**
   * Handler function for Delete gift button click.
   */
  function handleDeleteGiftClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.delete,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    storeConfirmationDialogData({
      cancelLabel: manageGiftStrings.dialogs.deleteScheduledGift.cancelLabel,
      confirmLabel: manageGiftStrings.dialogs.deleteScheduledGift.confirmLabel,
      icon: null,
      isOpen: true,
      message: manageGiftStrings.dialogs.deleteScheduledGift.message,
      onCancelClick: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({ isOpen: false });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: manageGiftStrings.dialogs.deleteScheduledGift.cancelLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onClose: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onConfirmClick: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: manageGiftStrings.dialogs.deleteScheduledGift.confirmLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        callApiDelete();
      },
      title: manageGiftStrings.dialogs.deleteScheduledGift.title,
    });
  }

  /**
   * Handler function for Save button click.
   */
  /* istanbul ignore next */
  function handleSaveGiftClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.save,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callApiUpdate();
  }

  /**
   * Handler function for close button click.
   *
   * Note: Ignore directive added only because passThroughProps is only ever
   * used in tests and will always be truthy for test coverage.
   */
  function handleClose() {
    /* istanbul ignore next */
    if (networkRequestStatus.processing) {
      return;
    }

    /* istanbul ignore next */
    const comparitor =
      passThroughProps?.mode && passThroughProps.testOverride
        ? passThroughProps.mode
        : modalMode;
    if (comparitor === MODAL_MODES.detail) {
      callSegmentTrack({
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.screens.names.manageGivingDetail,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.back,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          screen: ANALYTICS.screens.names.manageGivingDetail,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      /**
       * If form has been updated, show confirmation dialog with save prompt.
       * Note: Ignore added since it's test-specific for override and all
       * within this condition is covered.
       */
      /* istanbul ignore next */
      if (passThroughProps?.isFormUpdated || isFormUpdated()) {
        storeConfirmationDialogData({
          cancelLabel:
            manageGiftStrings.dialogs.manageScheduledGiftUnsavedChanges
              .cancelLabel,
          confirmLabel:
            manageGiftStrings.dialogs.manageScheduledGiftUnsavedChanges
              .confirmLabel,
          icon: null,
          isOpen: true,
          message:
            manageGiftStrings.dialogs.manageScheduledGiftUnsavedChanges.message,
          onCancelClick: /* istanbul ignore next */ () => {
            storeConfirmationDialogData({ isOpen: false });
            callSegmentTrack({
              event: ANALYTICS.events.buttonAction,
              properties: {
                action: ANALYTICS.actions.clicked,
                component: ANALYTICS.screens.names.manageGivingDetailModal,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label:
                  manageGiftStrings.dialogs.manageScheduledGiftUnsavedChanges
                    .cancelLabel,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                screen: ANALYTICS.screens.names.manageGivingDetailModal,
                title: document?.title,
                url: window?.location?.href,
                user_id:
                  user?.['https://www.life.church/rock_person_alias_id'] ||
                  null,
              },
            });
            // Timeout to match transition time of dialog hiding.
            setTimeout(() => {
              setModalMode(MODAL_MODES.main);
            }, 300);
          },
          onClose: /* istanbul ignore next */ () => {
            storeConfirmationDialogData({
              isOpen: false,
            });
            callSegmentTrack({
              event: ANALYTICS.events.buttonAction,
              properties: {
                action: ANALYTICS.actions.clicked,
                component: ANALYTICS.screens.names.manageGivingDetailModal,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.close,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                screen: ANALYTICS.screens.names.manageGivingDetailModal,
                title: document?.title,
                url: window?.location?.href,
                user_id:
                  user?.['https://www.life.church/rock_person_alias_id'] ||
                  null,
              },
            });
          },
          onConfirmClick: /* istanbul ignore next */ () => {
            storeConfirmationDialogData({
              isOpen: false,
            });
            callSegmentTrack({
              event: ANALYTICS.events.buttonAction,
              properties: {
                action: ANALYTICS.actions.clicked,
                component: ANALYTICS.screens.names.manageGivingDetailModal,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label:
                  manageGiftStrings.dialogs.manageScheduledGiftUnsavedChanges
                    .confirmLabel,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                screen: ANALYTICS.screens.names.manageGivingDetailModal,
                title: document?.title,
                url: window?.location?.href,
                user_id:
                  user?.['https://www.life.church/rock_person_alias_id'] ||
                  null,
              },
            });
            callApiUpdate();
          },
          title: null,
        });
      } else {
        /* istanbul ignore next */
        setModalMode(MODAL_MODES.main);
      }
    } else {
      callSegmentTrack({
        event: ANALYTICS.events.buttonAction,
        properties: {
          action: ANALYTICS.actions.clicked,
          component: ANALYTICS.screens.names.manageGivingList,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.close,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          screen: ANALYTICS.screens.names.manageGivingList,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });
      handleModalClose(modals.manageGiving.id);
    }
  }

  /**
   * Handler function for manage giving gift list item click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for main mode > list item click,
   * and due to setting state var within it.
   *
   * @param {PaymentMethod} data - The selected gift entry data object.
   */
  /* istanbul ignore next */
  function handleManageGivingListItemClick(data) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingList,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.scheduledGiftDetail,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingList,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: data?.id,
      },
    });

    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
    storeScheduledGiftData({
      attributes: data.attributes,
      id: data.id,
      type: data.type,
    });
    setInitialScheduledGiftData({
      attributes: data.attributes,
      id: data.id,
      type: data.type,
    });
    setModalMode(MODAL_MODES.detail);
  }

  /**
   * Handler function for Manage Gift form change events.
   *
   * Note: Ignore directive added since event handler test coverage is added to
   * the ManageGift component tests.
   *
   * @param {object} formData - Data object of Manage Gift form data.
   */
  /* istanbul ignore next */
  function handleManageGiftFormChange(formData) {
    const hasErrors =
      !formData?.attributes?.amount ||
      parseInt(formData?.attributes?.amount, 10) <= 0 ||
      Number.isNaN(parseInt(formData?.attributes?.amount, 10)) ||
      !formData?.attributes?.campus ||
      !formData?.attributes?.fund ||
      !formData?.attributes?.payment_method;
    setEditManageGiftFormHasErrors(hasErrors);
  }

  /**
   * Handler function for screen 1 content scroll event, which sets the scroll
   * position for the screen to be stored for ensuring accurate placement kept.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  /* istanbul ignore next */
  function handleScreen1ContentScroll() {
    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
  }

  /**
   * Convenience async function to fetch scheduled gifts from the GivingContext.
   *
   * Note: Ignore directive added since data is provided via tests and this
   * would only run in an instance of no provided prop data.
   */
  /* istanbul ignore next */
  async function fetchScheduledGiftsData() {
    const scheduledGiftsResult = await fetchScheduledGifts();
    setGiftListData(scheduledGiftsResult);
  }

  /**
   * Single-run convenience effect to set and store scheduled gifts data.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main effect/logic exists.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (initGivingData) {
      setGiftListData(initGivingData);

      if (
        passThroughProps?.mode === MODAL_MODES.detail &&
        !passThroughProps?.testOverride &&
        passThroughProps?.initGiftData
      ) {
        const activeScrollTop = screen1ContentRef?.current?.scrollTop;
        setScrollPosition({
          screen1: activeScrollTop,
        });
        storeScheduledGiftData(passThroughProps?.initGiftData);
        setInitialScheduledGiftData(passThroughProps?.initGiftData);
        setModalMode(MODAL_MODES.detail);
      }
    } else {
      fetchScheduledGiftsData(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to set the scroll listener for the first screen.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef.current) {
      screen1ContentRef?.current?.addEventListener(
        'scroll',
        handleScreen1ContentScroll,
      );
    }
  }, [screen1ContentRef]);

  /**
   * Convenience effect to ensure the scroll position is kept for the first
   * screen, as potential re-renders may occur with transition to screen 2.
   * While this looks nearly identical to the effect above, its dependency
   * array ensures it fires off when modal mode or scroll position changes, not
   * just when the content ref gets set (as is the case above).
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = scrollPosition?.screen1;
    }
  }, [modalMode, scrollPosition.screen1]);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.manageGiving,
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: preferredCampus,
        referrer: document?.referrer,
        screen_class: ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    const segmentPropertiesLabel =
      (passThroughProps?.mode === MODAL_MODES.detail &&
        passThroughProps.testOverride) ||
      modalMode === MODAL_MODES.detail
        ? ANALYTICS.labels.manageGivingDetail
        : ANALYTICS.labels.manageGiving;

    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.manageGiving,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: segmentPropertiesLabel,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content manage-giving';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName =
    iconOverride && iconOverride === ICON_OVERRIDES.back
      ? 'modal-header-mode-back'
      : '';

  /**
   * Note: Ignoring due to modalMode being set within component state. Other
   * individual component/screen functionality covered in respective tests.
   */
  /* istanbul ignore next */
  if ([MODAL_MODES.detail].includes(modalMode)) {
    contentPlacementClass = 'active-2';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      className={/* istanbul ignore next */ iconOverride ? 'animate-rtl' : ''}
      content={
        <div
          className={[contentClassName, contentPlacementClass].join(' ')}
          data-testid="manage-giving-modal"
        >
          {giftListData ? (
            <div className="screen-1">
              <ManageGivingList
                contentRef={screen1ContentRef}
                giftListData={giftListData}
                onEntryListItemClick={handleManageGivingListItemClick}
                onGiveNowClick={handleGiveNowClick}
              />
            </div>
          ) : (
            <Loading />
          )}
          <div className="screen-2">
            <div className="screen-content" ref={screen2ContentRef}>
              {
                /* istanbul ignore next */ modalMode === MODAL_MODES.detail ? (
                  <ManageGift
                    frequencyLabel={frequencyLabel}
                    isLocationCurrentLocation={isLocationCurrentLocation}
                    onFormChange={
                      passThroughProps?.onManageGiftChange ||
                      handleManageGiftFormChange
                    }
                  />
                ) : null
              }
            </div>
          </div>
        </div>
      }
      contentClassName="pt-none animatable"
      data-testid="manage-giving-modal"
      footer={
        (passThroughProps?.testOverride &&
          passThroughProps?.mode === MODAL_MODES.detail) ||
        modalMode === MODAL_MODES.detail ? (
          <div className="btn-lineup">
            <StyledButton
              disabled={networkRequestStatus.processing}
              onClick={handleDeleteGiftClick}
              variant={ButtonVariants.secondary}
            >
              {
                /* istanbul ignore next */ networkRequestStatus.processing &&
                networkRequestStatus.method === HTTP_METHODS.del ? (
                  <div className="circular loader"></div>
                ) : (
                  <>{STRINGS.labels.delete}</>
                )
              }
            </StyledButton>
            <StyledButton
              disabled={
                networkRequestStatus.processing || editManageGiftFormHasErrors
              }
              onClick={handleSaveGiftClick}
              variant={ButtonVariants.primary}
            >
              {
                /* istanbul ignore next */ networkRequestStatus.processing &&
                networkRequestStatus.method === HTTP_METHODS.patch ? (
                  <div className="circular loader"></div>
                ) : (
                  <>{STRINGS.labels.save}</>
                )
              }
            </StyledButton>
          </div>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            (passThroughProps?.testOverride &&
              passThroughProps?.mode === MODAL_MODES.detail) ||
            modalMode === MODAL_MODES.detail ? (
              <button
                data-testid="manage-giving-help-button"
                onClick={handleHelpClick}
              >
                {manageGivingStrings.main.labels.help}
              </button>
            ) : (
              <button
                data-testid="manage-giving-add-button"
                onClick={handleAddClick}
              >
                {manageGivingStrings.main.labels.add}
              </button>
            )
          }
          onCloseClick={handleClose}
          title={manageGivingStrings.title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
