/**
 * @module AppIndex
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';
import { GivingProvider } from './context/GivingContext';
import packageJson from '../package.json';
import {
  CALLBACKS,
  MODALS,
  MODAL_MODES,
  loadNewRelicBrowserMonitor,
  loadSegment,
} from './utils';
import Callback from './views/Callback';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
const basePath = '/give';

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  });
}
if (process.env.SEGMENT_KEY && process.env.SEGMENT_URL) {
  /**
   * Fetch user from local storage to pass along to Segment for analytics.
   */
  const user = JSON.parse(window?.localStorage?.getItem('user_profile'));
  const pagePayload = {
    category: document?.title?.split('|')?.[0]?.trim() || '',
    name: document?.title || '',
    properties: {
      logged_in: !!user,
      path: window?.location?.pathname,
      preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
      referrer: document?.referrer || null,
      title: document?.title || '',
      url: window?.location?.href,
      user_id: user?.['https://www.life.church/rock_person_alias_id'],
    },
  };
  loadSegment({ autoLoad: true, pagePayload });
}

const nrMonitoringScript = document.querySelector('script[data-monitoring-nr]');
if (
  Boolean(process.env.NEW_RELIC_BROWSER_MONITOR_ENABLE) &&
  !nrMonitoringScript?.length
) {
  nrMonitoringScript.innerHTML = loadNewRelicBrowserMonitor();
}

/**
 * Represents a wrapper component with navigate functionality to handle routes
 * with redirects for giving history, which may or may not include search params
 * as part of the URL. If the 'year' search param is found on the URL, it is
 * used as a URL path param to trigger the specific giving history year to be
 * shown in the Giving History modal view.
 */
function History() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [givingHistoryYear] = React.useState(
    Number(searchParams.get('year')) || null,
  );
  React.useEffect(() => {
    navigate(`${basePath}/history/${givingHistoryYear || ''}`, {
      replace: true,
    });
  }, [givingHistoryYear, navigate]);
}

/**
 * Note: The `?` at the end of paths is the optional segment flag. This means
 * that a path `/give?/help` will load the element for both `/give/help` as well
 * as simply `/help`, since they are both treated the same.
 *
 * Source: https://reactrouter.com/en/main/route/route#optional-segments.
 */
const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    path: '/',
  },
  {
    element: (
      <ErrorBoundary>
        <Navigate replace="true" to={`${basePath}/my_giving`} />
      </ErrorBoundary>
    ),
    path: '/profile/giving',
  },
  {
    element: (
      <ErrorBoundary>
        <History />
      </ErrorBoundary>
    ),
    path: '/profile/giving/history',
  },
  {
    element: (
      <ErrorBoundary>
        <Navigate replace="true" to={`${basePath}/statements`} />
      </ErrorBoundary>
    ),
    path: '/profile/giving/giving-history-pdf',
  },
  {
    element: (
      <ErrorBoundary>
        <Navigate replace="true" to={`${basePath}/payment_methods`} />
      </ErrorBoundary>
    ),
    path: '/profile/giving/methods',
  },
  {
    element: (
      <ErrorBoundary>
        <Navigate replace="true" to={`${basePath}/manage_giving`} />
      </ErrorBoundary>
    ),
    path: '/profile/giving/schedules',
  },
  {
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    path: basePath,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.help.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/help`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history/:givingHistoryYear`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history/:givingHistoryYear/:givingHistoryDetailId`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history/:givingHistoryYear/detail/:givingHistoryDetailId`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history_downloads`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingHistory.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/history_downloads/:givingHistoryYear`,
  },
  {
    element: (
      <ErrorBoundary>
        <App
          initialModalId={MODALS.givingHistory.id}
          initialModalMode={MODAL_MODES.givingStatements}
        />
      </ErrorBoundary>
    ),
    path: `${basePath}?/statements`,
  },
  {
    element: (
      <ErrorBoundary>
        <App
          initialModalId={MODALS.givingHistory.id}
          initialModalMode={MODAL_MODES.givingStatements}
        />
      </ErrorBoundary>
    ),
    path: `${basePath}?/statements/:givingHistoryYear`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.paperlessPreference.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/statements/paperless`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.manageGiving.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/manage_giving`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.manageGiving.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/manage_giving/:giftId`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.givingMenu.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/my_giving`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.managePaymentMethod.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/payment_methods`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.paymentMethod.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/select_payment_method`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.paymentMethod.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/payment_methods/add`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.managePaymentMethod.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/payment_methods/:paymentMethodId`,
  },
  {
    element: (
      <ErrorBoundary>
        <App initialModalId={MODALS.managePaymentMethod.id} />
      </ErrorBoundary>
    ),
    path: `${basePath}?/payment_methods/:paymentMethodId/edit`,
  },
  {
    element: (
      <ErrorBoundary>
        <Callback />
      </ErrorBoundary>
    ),
    path: CALLBACKS.payPal.path,
  },
  {
    element: (
      <ErrorBoundary>
        <Callback />
      </ErrorBoundary>
    ),
    path: CALLBACKS.auth0.path,
  },
  {
    element: (
      <ErrorBoundary>
        <Navigate replace="true" to="/" />
      </ErrorBoundary>
    ),
    path: '*',
  },
]);

root.render(
  <AuthProvider>
    <GivingProvider>
      <ModalProvider>
        <RouterProvider router={router}></RouterProvider>
      </ModalProvider>
    </GivingProvider>
  </AuthProvider>,
);
