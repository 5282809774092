/**
 * @module Header
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { base } from '@io/web-tools-io/dist/themes';
import AuthMenu from '../AuthMenu';
import { HeaderLocationIcon } from '../LocationIcon';
import SelectLabel from '../SelectLabel';
import useAuth from '../../hooks/useAuth';
import LifeChurchIcon from '../ui/LifeChurchIcon';
import { FORM_MODES, STRINGS } from '../../utils';
import './Header.scss';

/**
 * Represents the start icon(s) for the header.
 *
 * @param {object} props - The component params object.
 * @param {boolean} [props.includeLocation] - Boolean flag denoting whether or not to include location icon.
 * @param {boolean} [props.isLocationCurrentLocation] - Boolean flag denoting whether or not the current location is the selected location.
 *
 * @returns {React.ReactElement} The HeaderStartIcon component.
 */
function HeaderStartIcon({ includeLocation, isLocationCurrentLocation }) {
  const color = isLocationCurrentLocation
    ? base.colors.utility.action.base
    : base.colors.utility.warning.base;

  return (
    <div className="icon-lineup">
      <LifeChurchIcon />
      {includeLocation ? <HeaderLocationIcon color={color} /> : null}
    </div>
  );
}

/**
 * Represents the header area of the project, including Location selector and
 * authentication links.
 *
 * @param {object} props - The component params object.
 * @param {string} [props.campusName] - The campus name.
 * @param {boolean} [props.includeLocation] - Boolean flag denoting whether or not to include location icon.
 * @param {boolean} [props.isLocationCurrentLocation] - Boolean flag denoting whether or not the current location is the selected location.
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main').
 * @param {Function} [props.onGivingMenuClick] - Handler function for the Giving Menu click event (from AuthMenu).
 * @param {Function} [props.onHelpClick] - Handler function for the Help click event (from AuthMenu).
 * @param {Function} [props.onLocationSelectClick] - Handler function for the Location Select click event.
 *
 * @returns {React.ReactElement} The Header component.
 */
function Header({
  campusName,
  includeLocation,
  isLocationCurrentLocation,
  mode = FORM_MODES.main,
  onGivingMenuClick,
  onHelpClick,
  onLocationSelectClick,
}) {
  const { isAuthenticated } = useAuth();

  return (
    <header className="header" data-testid="header">
      <nav
        className={`navigation container ${
          isAuthenticated ? 'inline' : ''
        }`.trim()}
      >
        <SelectLabel
          className={includeLocation ? 'dual-icons' : ''}
          label={campusName || STRINGS.labels.defaultLocation}
          onClick={() => {
            onLocationSelectClick({ mode });
          }}
          startIcon={
            <HeaderStartIcon
              includeLocation={includeLocation}
              isLocationCurrentLocation={isLocationCurrentLocation}
            />
          }
        />
        {mode === FORM_MODES.main ? (
          <AuthMenu
            onGivingMenuClick={onGivingMenuClick}
            onHelpClick={onHelpClick}
          />
        ) : null}
      </nav>
    </header>
  );
}

export default Header;
