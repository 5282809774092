/* eslint-disable no-console */
/**
 * @module AuthContext
 */
import React from 'react';
import auth0 from 'auth0-js';
import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import Moment from 'moment';
import randomstring from 'randomstring';
import { callSegmentIdentify } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getPaperlessStatus, getUser } from '../api/profile';
import {
  CALLBACKS,
  LOCAL_STORAGE_KEYS,
  STATUS_TYPES,
  STRINGS,
  logError,
} from '../utils';

/**
 * Note: The contents of this file are leveraged from the source code for the
 * Magnolia Client and Life.Church Site v2 projects. Since this logic is now in
 * a Context and Provider, its structure and logical flow differs from original.
 *
 * @see {@link https://in.thewardro.be/io/interactive/magnolia-cms/-/blob/integration/client/src/helpers/checkLogin.js}.
 * @see {@link https://in.thewardro.be/io/interactive/lifechurch-site-v2/-/blob/integration/app/javascript/GivingApp/Auth/Auth.js}.
 * @see {@link https://auth0.com/docs/libraries/auth0js#initialization}.
 */

/**
 * @typedef {object} AuthContext
 * @property {Function} checkLogin - Convenience function to check for existing Login status for a user.
 * @property {Function} checkSession - Convenience function to check for existing session status for a user and enable silent authentication.
 * @property {Function} fetchProfileData - Function to retrieve user profile data from the Life.Church Profiles API.
 * @property {Function} getAccessToken - Convenience function to check for and return authentication access token.
 * @property {Function} handleAuthentication - Handler function for authentication callback.
 * @property {boolean} isAuthenticated - Boolean flag denoting authentication status of the user.
 * @property {boolean} isError - Boolean flag denoting error status of authentication.
 * @property {boolean} isIdle - Boolean flag denoting idle status of authentication.
 * @property {boolean} isLoading - Boolean flag denoting loading status of authentication.
 * @property {boolean} isOrganization - Boolean flag denoting organization status of the user.
 * @property {boolean} isSuccess - Boolean flag denoting success status of authentication.
 * @property {boolean} isUserChecked - Boolean flag denoting checked status of a user.
 * @property {Function} logIn - Convenience function to trigger login process.
 * @property {Function} logOut - Convenience function to log out user.
 * @property {User} user - Authenticated user data object.
 * @property {boolean} userPaperlessStatus - Boolean flag denoting user paperless statement status.
 * @property {UserProfileData} userProfileData - Object of user profile data.
 */

export const AuthContext = React.createContext({
  checkLogin: null,
  checkSession: null,
  fetchProfileData: null,
  getAccessToken: null,
  handleAuthentication: null,
  isAuthenticated: false,
  isError: false,
  isIdle: true,
  isLoading: false,
  isOrganization: false,
  isSuccess: true,
  isUserChecked: false,
  logIn: null,
  logOut: null,
  user: null,
  userPaperlessStatus: null,
  userProfileData: null,
});
AuthContext.displayName = 'AuthContext';

// Global variables and values
const audience = process.env.AUTH_AUDIENCE;
const domain = process.env.AUTH_DOMAIN;
const clientID = process.env.AUTH_CLIENT_ID;
const webAuth = new auth0.WebAuth({
  audience,
  clientID,
  domain,
  redirectUri: `${window.location.origin}${CALLBACKS.auth0.path}/`,
  responseType: 'token id_token',
  scope: 'openid profile',
});

/**
 * React Context Provider for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactNode} props.children - The React children.
 *
 * @returns {React.ReactElement} The Auth Context provider.
 */
export function AuthProvider({ children, ...props }) {
  // Convenience variables for Auth-related strings values.
  const authStrings = STRINGS.auth;
  const {
    cookies: authCookies,
    errors: authErrors,
    localStorageKeys,
    profile: authProfile,
  } = authStrings;

  // Set up state-based elements.
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isOrganization, setIsOrganization] = React.useState(false);
  const [isUserChecked, setIsUserChecked] = React.useState(false);
  const [status, setStatus] = React.useState(STATUS_TYPES.idle);
  const [user, setUser] = React.useState(null);
  const [userPaperlessStatus, setUserPaperlessStatus] = React.useState(false);
  const [userProfileData, setUserProfileData] = React.useState(null);

  // Convenience variables determined by set status type and returned for use.
  const isLoading = status === STATUS_TYPES.pending;
  const isIdle = status === STATUS_TYPES.idle;
  const isSuccess = status === STATUS_TYPES.resolved;
  const isError = status === STATUS_TYPES.rejected;

  // Convenience state for initial login check.
  const [isInitialLoginChecked, setIsInitialLoginChecked] =
    React.useState(false);

  /**
   * Convenience function to redirect to main Giving app.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.error] - Optional error message to store for display of main app.
   *
   * @returns {Function} Invocation of window.open() method.
   */
  const redirectToMainGivingApp = React.useCallback(
    ({ error } = {}) => {
      if (error) {
        window.localStorage.setItem(localStorageKeys.errorAlert, error);
      } else {
        window.localStorage.removeItem(localStorageKeys.errorAlert);
      }
      return window.open(`${window.location.origin}/give/`, '_self');
    },
    [localStorageKeys],
  );

  /**
   * Convenience method to check whether the current time is past the Access
   * Token's expiry time and return a boolean value accordingly.
   *
   * @returns {boolean} Boolean flag denoting user authentication status.
   */
  const checkAuthenticationStatus = React.useCallback(() => {
    const expiresAt = !Number.isNaN(
      Number(Cookies.get(authCookies.expiresAt)) * 1000,
    )
      ? Number(Cookies.get(authCookies.expiresAt)) * 1000
      : Number(window.localStorage.getItem(localStorageKeys.expiresAt)) * 1000;
    return Number(new Date().getTime()) < Number(expiresAt);
  }, [authCookies, localStorageKeys.expiresAt]);

  /**
   * Convenience function to check and return whether or not the supplied user
   * data object is attributed to a business profile or personal profile.
   *
   * @param {object} userProfileInfo - User profile data object.
   *
   * @returns {boolean} Boolean flag denoting whether user profile is business.
   */
  const checkOrganizationProfile = React.useCallback(
    (userProfileInfo) => {
      return Boolean(
        userProfileInfo?.[authProfile.userMetadataUrl]?.organizationType,
      );
    },
    [authProfile],
  );

  /**
   * Convenience function to destroy the user session for log out request.
   */
  const destroyUserSession = React.useCallback(() => {
    setIsAuthenticated(false);
    setIsOrganization(false);
    setIsUserChecked(false);
    setStatus(STATUS_TYPES.idle);
    setUser(null);
    setUserPaperlessStatus(false);
    setUserProfileData(null);

    window.localStorage.removeItem(localStorageKeys.accessToken);
    window.localStorage.removeItem(localStorageKeys.authorizeState);
    window.localStorage.removeItem(localStorageKeys.expiresAt);
    window.localStorage.removeItem(localStorageKeys.userProfile);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.userGivingData);

    Cookies.remove(authCookies.accessToken);
    Cookies.remove(authCookies.expiresAt);
  }, [authCookies, localStorageKeys]);

  /**
   * Convenience function to return stored value for authorization state.
   *
   * @returns {string} Stored encoded string value for authorization state.
   */
  const getEncodedString = React.useCallback(() => {
    return window.localStorage.getItem(localStorageKeys.authorizeState);
  }, [localStorageKeys]);

  /**
   * Convenience function to set and store an encoded string used for auth.
   *
   * @returns {Function} Invocation of setting local storage auth state value.
   */
  const setEncodedString = React.useCallback(() => {
    const string = Base64.encode(randomstring.generate());
    return window.localStorage.setItem(localStorageKeys.authorizeState, string);
  }, [localStorageKeys]);

  /**
   * Convenience function to retrieve and return authentication access token.
   */
  const getAccessToken = React.useCallback(() => {
    const storedAccessToken =
      Cookies.get(authCookies.accessToken) ??
      window.localStorage.getItem(localStorageKeys.accessToken);
    return storedAccessToken;
  }, [authCookies.accessToken, localStorageKeys]);

  /**
   * Convenience function to call the Life.Church Profile API to retrieve user data.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   * @param {Function} [params.forceFetch] - Optional boolean flag to ensure data is fetched. If false, and if profile data has been retrieved, the stored data is returned.
   */
  const fetchProfileData = React.useCallback(
    async ({ callback, forceFetch } = {}) => {
      if (!userProfileData || forceFetch) {
        const accessToken = getAccessToken();
        setStatus(STATUS_TYPES.pending);

        try {
          const userProfileResponse = await getUser({
            accessToken,
          });
          const userPaperlessStatusResponse = await getPaperlessStatus({
            accessToken,
          });
          /**
           * Note: Profiles API unfortunately returns string-based boolean that
           * may or may not be title-case capitalized. As such, adding in the
           * extra checks for toString() and toLowerCase() to ensure accuracy.
           */
          const statusValue =
            userPaperlessStatusResponse?.Value?.toString().toLowerCase() ===
              'true' || false;
          setUserPaperlessStatus(statusValue);
          setUserProfileData(userProfileResponse || null);
          setStatus(STATUS_TYPES.idle);
          if (callback) {
            callback({
              paperlessStatus: statusValue,
              userProfileData: userProfileResponse?.data,
            });
          }
        } catch (error) {
          setStatus(STATUS_TYPES.idle);
          logError(error);

          if (callback) {
            callback({ error });
          }
        }
      } else if (callback && typeof callback === 'function') {
        callback({ paperlessStatus: userPaperlessStatus, userProfileData });
      }
    },
    [getAccessToken, userPaperlessStatus, userProfileData],
  );

  /**
   * Handler function to save authentication result.
   *
   * @param {AuthResult} authResult - The authentication data object.
   */
  const saveAuthResult = React.useCallback(
    ({ authResult, callback = () => {} }) => {
      const expiresAt = parseInt(
        (Moment() + Moment.unix(authResult.expiresIn).utc()) / 1000,
        10,
      );
      window.localStorage.setItem(
        localStorageKeys.accessToken,
        authResult.accessToken,
      );
      window.localStorage.setItem(localStorageKeys.expiresAt, expiresAt);

      Cookies.set(authCookies.accessToken, authResult.accessToken, {
        path: '/',
      });
      Cookies.set(authCookies.expiresAt, expiresAt, { path: '/' });

      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    [authCookies, localStorageKeys],
  );

  /**
   * Fetch and set user profile via Auth0 Web Auth client.
   *
   * @param {AuthResult} authResult - Optional AuthResult object used to fetch the profile (Default: null).
   *
   * @returns {Function} Log or setUser function invocation, depending on user profile existence and status.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#extract-the-authresult-and-get-user-info}.
   */
  const fetchProfile = React.useCallback(
    ({ authResult = null, callback = () => {} } = {}) => {
      if (webAuth) {
        const userProfile = JSON.parse(
          window.localStorage.getItem(localStorageKeys.userProfile),
        );
        if (userProfile) {
          window.localStorage.setItem(
            localStorageKeys.userProfile,
            JSON.stringify(userProfile),
          );
          setUser(userProfile);
          setIsAuthenticated(true);
          setIsOrganization(checkOrganizationProfile(userProfile));
          setIsUserChecked(true);
          fetchProfileData({
            callback: () => {
              if (callback && typeof callback === 'function') {
                callback();
              }
            },
          });
        }
        if (!userProfile && !user) {
          const authAccessToken =
            authResult?.accessToken || Cookies.get(authCookies.accessToken);
          if (!authAccessToken) {
            // Note: There is no need to report this to Bugsnag, as it safe to
            // silently fail and trigger callback().
            logError(new Error('Access token must exist to fetch profile.'), {
              browserConsole: true,
              bugsnag: false,
              windowAlert: false,
            });
            if (callback && typeof callback === 'function') {
              callback();
            }
          } else {
            setStatus(STATUS_TYPES.pending);
            webAuth.client.userInfo(authAccessToken, (err, profile) => {
              setStatus(STATUS_TYPES.idle);
              if (profile) {
                window.localStorage.setItem(
                  localStorageKeys.userProfile,
                  JSON.stringify(profile),
                );
                setUser(profile);
                setIsAuthenticated(true);
                setIsOrganization(checkOrganizationProfile(profile));
                setIsUserChecked(true);
                fetchProfileData({
                  callback: () => {
                    if (callback && typeof callback === 'function') {
                      callback();
                    }
                  },
                });
              } else if (err) {
                // Note: There is no need to report this to Bugsnag, as like
                // above, it is safe to silently fail and trigger callback().
                logError(err.original, { bugsnag: false });
                if (callback && typeof callback === 'function') {
                  callback();
                }
              } else if (callback && typeof callback === 'function') {
                logError(
                  new Error(
                    'webAuth.client.userInfo: No error or profile object.',
                  ),
                  { bugsnag: false, windowAlert: false },
                );
                callback();
              }
            });
          }
        }
      }
    },
    [
      authCookies,
      checkOrganizationProfile,
      fetchProfileData,
      localStorageKeys,
      user,
    ],
  ); // NOSONAR

  /**
   * Handler function for authentication callback. This includes ensuring the
   * presence of required values in window.location.hash before parsing and
   * continuing authentication.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#extract-the-authresult-and-get-user-info}.
   */
  const handleAuthentication = React.useCallback(() => {
    if (webAuth) {
      if (/access_token|id_token|error/.test(window.location.hash)) {
        setStatus(STATUS_TYPES.pending);
        webAuth.parseHash(
          { hash: window.location.hash, state: getEncodedString() },
          (err, authResult) => {
            setStatus(STATUS_TYPES.idle);
            if (authResult?.accessToken) {
              Log.log('webAuth.parseHash: authResult.accessToken exists.');
              saveAuthResult({
                authResult,
                callback: () => {
                  fetchProfile({
                    authResult,
                    callback: () => {
                      redirectToMainGivingApp();
                    },
                  });
                },
              });
            } else if (
              err &&
              err.error === 'unauthorized' &&
              err.errorDescription === 'user is blocked'
            ) {
              // For this and the other errors logged below, there is no need to
              // report to Bugsnag, as unauthorized/blocked users is no reason
              // to raise a red flag by triggering in Bugsnag. Especially for
              // this error, it's only meant and needed to be shown to the user.
              logError(new Error(err.errorDescription), {
                browserConsole: true,
                bugsnag: false,
                windowAlert: true,
              });
              redirectToMainGivingApp({
                error: authErrors.accountDisabled,
              });
            } else if (err) {
              logError(new Error(err.errorDescription), {
                browserConsole: true,
                bugsnag: false,
                windowAlert: false,
              });
              logError(new Error(STRINGS.auth.errors.loginError), {
                browserConsole: false,
                bugsnag: false,
                windowAlert: true,
              });
              redirectToMainGivingApp({
                error: authErrors.loginError,
              });
            } else {
              logError(new Error('No auth result access token, no error.'), {
                browserConsole: true,
                bugsnag: false,
                windowAlert: false,
              });
            }
          },
        );
      }
    }
  }, [
    authErrors,
    fetchProfile,
    getEncodedString,
    redirectToMainGivingApp,
    saveAuthResult,
  ]);

  /**
   * Convenience function to check session status for user authentication.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#using-checksession-to-acquire-new-tokens}.
   */
  const checkSession = React.useCallback(
    ({ callback = () => {} } = {}) => {
      if (webAuth) {
        // Note: Not setting any parameters ensures those used with `authorize` are used.
        webAuth.checkSession({}, (err, result) => {
          if (err) {
            setIsAuthenticated(false);
            destroyUserSession();
            if (callback && typeof callback === 'function') {
              callback();
            }
          } else if (result) {
            saveAuthResult({
              authResult: result,
              callback: () => {
                fetchProfile({ authResult: result, callback });
              },
            });
          } else {
            logError(
              new Error('webAuth.checkSession: No error or result object.'),
              { bugsnag: false, windowAlert: false },
            );
          }
        });
      }
    },
    [destroyUserSession, fetchProfile, saveAuthResult],
  );

  /**
   * Convenience function to check the Login status for a user.
   */
  const checkLogin = React.useCallback(() => {
    const userProfile =
      JSON.parse(window.localStorage.getItem(localStorageKeys.userProfile)) ??
      user;

    /**
     * Authentication check and value setting.
     *
     * Note: Explicitly setting state separate from function check of auth
     * status to ensure no delay or lapse in state within this file.
     */
    if (!checkAuthenticationStatus()) {
      setIsAuthenticated(false);
      destroyUserSession();
      checkSession();
    } else if (userProfile) {
      setUser(userProfile);
      setIsAuthenticated(true);
      setIsOrganization(checkOrganizationProfile(userProfile));
      setIsUserChecked(true);
      fetchProfileData();

      // Segment identify trigger.
      const segmentUser = {};
      const userAnalyticsKeys = [
        'sub',
        'locale',
        'nickname',
        'https://www.life.church/rock_person_alias_id',
      ];
      userAnalyticsKeys.forEach((key) => {
        if (userProfile[key]) {
          let userKey = null;
          switch (key) {
            case 'sub':
              userKey = 'auth0_id';
              break;
            case 'nickname':
              userKey = 'webnickname';
              break;
            case 'https://www.life.church/rock_person_alias_id':
              userKey = 'userId';
              break;
            case 'locale':
              userKey = 'language';
              break;
            default:
              break;
          }
          segmentUser[userKey] = userProfile[key];
        }
      });

      if (
        window?.analytics?.identify &&
        userProfile['https://www.life.church/rock_person_alias_id']
      ) {
        /* istanbul ignore next */
        callSegmentIdentify({
          traits: segmentUser,
          userId: userProfile['https://www.life.church/rock_person_alias_id'],
        });
      }
    } else {
      logError(
        new Error(
          'checkLogin: No userProfile found, but valid authenticationStatus() retrieved.',
        ),
        { bugsnag: false, windowAlert: false },
      );
    }
  }, [
    localStorageKeys,
    checkAuthenticationStatus,
    checkOrganizationProfile,
    checkSession,
    destroyUserSession,
    fetchProfileData,
    user,
  ]);

  /**
   * Convenience function to build authentication URL and open in new window.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#buildauthorizeurl-options-}.
   */
  const logIn = React.useCallback(() => {
    setEncodedString();
    const url = webAuth.client.buildAuthorizeUrl({
      clientID,
      nonce: getEncodedString(),
      redirectUri: `${window.location.origin}${CALLBACKS.auth0.path}/`,
      state: getEncodedString(),
    });
    window.open(url, '_top');
  }, [getEncodedString, setEncodedString]);

  /**
   * Convenience function to log out user by removing data and triggering logout
   * on auth0 object.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#logout}.
   */
  const logOut = React.useCallback(() => {
    destroyUserSession();
    webAuth.logout({
      clientID,
      returnTo: window.location.origin,
    });
  }, [destroyUserSession]);

  /**
   * Single-run convenience effect to fetch data and set up Auth0 Web Auth.
   *
   * @see {@link https://auth0.com/docs/libraries/auth0js#initialization}.
   */
  React.useEffect(() => {
    if (webAuth && !isInitialLoginChecked) {
      setIsInitialLoginChecked(true);
      checkLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoginChecked, webAuth]);

  const value = React.useMemo(
    () => ({
      checkLogin,
      checkSession,
      fetchProfileData,
      getAccessToken,
      handleAuthentication,
      isAuthenticated,
      isError,
      isIdle,
      isLoading,
      isOrganization,
      isSuccess,
      isUserChecked,
      logIn,
      logOut,
      user,
      userPaperlessStatus,
      userProfileData,
    }),
    [
      checkLogin,
      checkSession,
      fetchProfileData,
      getAccessToken,
      handleAuthentication,
      isAuthenticated,
      isError,
      isIdle,
      isLoading,
      isOrganization,
      isSuccess,
      isUserChecked,
      logIn,
      logOut,
      user,
      userPaperlessStatus,
      userProfileData,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AuthContext.Provider value={value} {...props}>
      {children}
    </AuthContext.Provider>
  );
}
