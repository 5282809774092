/**
 * @module Confirmation
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentPage } from '@io/web-tools-io/dist/utils/helpers/analytics';
import confirmationCheckmarkCircled from '../../assets/svg/confirmation-checkmark-circled.svg';
import useAuth from '../../hooks/useAuth';
import useGiving from '../../hooks/useGiving';
import {
  ANALYTICS,
  BREAKPOINTS,
  POST_DONATION_CTA_ACTION_TYPES,
  STRINGS,
  isValidHttpUrl,
} from '../../utils';
import './Confirmation.scss';

const { confirmation: confirmationStrings } = STRINGS;

/**
 * Represents the confirmation screen shown to a user after successful giving.
 *
 * @param {object} props - The component props object.
 * @param {DonationData} props.donationData - The DonationData object.
 * @param {Function} props.onDoneClick - Handler function for Done button click.
 * @param {Function} props.onOneTimeDonationCtaClick - Handler function for One Time donation CTA button click.
 * @param {Function} props.onRecurringDonationCtaClick - Handler function for Recurring donation CTA button click.
 *
 * @returns {React.ReactElement} The Confirmation screen component.
 */
export function Confirmation({
  donationData,
  onDoneClick,
  onOneTimeDonationCtaClick,
  onRecurringDonationCtaClick,
}) {
  const { user } = useAuth();
  const { preferredCampus, storeUserGivingData, userGivingData } = useGiving();
  const { body, cta, email_body, subscription, title } =
    donationData.attributes;
  const { cta_action_type, cta_image_url } = cta;
  let {
    cta_action_url,
    cta_button_title,
    cta_card_title,
    cta_dismiss_button_text,
  } = cta;
  let mobile = cta_image_url?.mobile ?? '';
  let tablet = cta_image_url?.tablet ?? '';
  const isRecurringFrequency =
    (subscription &&
      cta_action_type !== POST_DONATION_CTA_ACTION_TYPES.openRecurring) ||
    false;
  const fallbackKey = isRecurringFrequency ? 'recurring' : 'onetime';

  // Explicitly set fallback values if not found.
  if (!cta_action_url) {
    cta_action_url =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_action_url;
  }
  if (!cta_button_title) {
    cta_button_title =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_button_title;
  }
  if (!cta_card_title) {
    cta_card_title =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_card_title;
  }
  if (!cta_dismiss_button_text) {
    cta_dismiss_button_text =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_dismiss_button_text;
  }
  if (
    !mobile ||
    !tablet ||
    !isValidHttpUrl(mobile) ||
    !isValidHttpUrl(tablet)
  ) {
    mobile =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_image_url.mobile;
    tablet =
      confirmationStrings.fallbackData[fallbackKey].cta.cta_image_url.tablet;
  }

  const actionWrapperStyles = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  /* istanbul ignore next */
  if (window.innerWidth <= BREAKPOINTS.md) {
    actionWrapperStyles.backgroundImage = `url(${mobile})`;
  } else {
    actionWrapperStyles.backgroundImage = `url(${tablet})`;
    actionWrapperStyles.backgroundPosition = '20% center';
  }

  /**
   * Handler function for Done CTA button click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleDoneClick(event) {
    onDoneClick({
      event,
      label: cta_dismiss_button_text,
      target: confirmationStrings.dismiss.cta.target,
      url: cta_action_url,
    });
  }

  /**
   * Handler function for One Time Donation CTA button click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleOneTimeDonationCtaClick(event) {
    onOneTimeDonationCtaClick({
      event,
      label: cta_button_title,
      target: confirmationStrings.recurringPrompt.cta.target,
      url: cta_action_url,
    });
  }

  /**
   * Handler function for Recurring Donation CTA button click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  function handleRecurringDonationCtaClick(event) {
    onRecurringDonationCtaClick({
      event,
      label: cta_button_title,
      target: confirmationStrings.recurringGift.cta.target,
      url: cta_action_url,
    });
  }

  /**
   * Single-run convenience effect to ensure all user giving data set properly,
   * and to trigger analytics events.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.givingResult,
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: preferredCampus,
        referrer: document?.referrer,
        screen_class: isRecurringFrequency
          ? ANALYTICS.screens.classes.recurring
          : ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    storeUserGivingData({
      ...userGivingData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container confirmation" data-testid="confirmation-screen">
      <div className="content-center text-center">
        <div>
          <img alt="icon" src={confirmationCheckmarkCircled} />
        </div>
        <h1>{title}</h1>
        <p className="main-message mb-8">{body}</p>
        <p className="sub-message mt-8">{email_body}</p>
        <div
          className={[
            'next-action-wrapper mt-36',
            isRecurringFrequency ? 'recurring' : '',
          ].join(' ')}
          style={actionWrapperStyles}
        >
          <div className="contents">
            <h2>{cta_card_title}</h2>
            <StyledButton
              className="reverse white"
              onClick={
                isRecurringFrequency
                  ? handleRecurringDonationCtaClick
                  : handleOneTimeDonationCtaClick
              }
              variant={ButtonVariants.secondary}
            >
              {cta_button_title}
            </StyledButton>
          </div>
        </div>
        <StyledButton
          className="mt-36"
          onClick={handleDoneClick}
          variant={ButtonVariants.secondary}
        >
          {cta_dismiss_button_text}
        </StyledButton>
      </div>
    </div>
  );
}
